import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import moment from 'moment';

// import arrowImageLast from '../../assets/images/big-white-arrow-last.png';
import './dailyProgressBlock.scss';
import { InfoOutlined } from '../icons';

const DailyProgressBlock = ({ days }) => {
    const secondsToMinutes = (sec) => sec / 60;
    const currentDate = moment().format('YYYY-M-DD');

    const renderDayItem = (dayItem) => {
        let extraClass = '';
        const minutes = secondsToMinutes(dayItem?.time);

        if (minutes >= 30) {
            extraClass = ' light-green';
        }

        if (minutes >= 45) {
            extraClass = ' dark-green';
        }

        if (dayItem.date === currentDate) {
            extraClass += ' current-day';
        }

        const percentage = (dayItem.time * 100) / 30;

        const tooltipTable = (
            <table className="tooltip-table">
                <tbody>
                    <tr>
                        <td>Date:</td>
                        <td>{dayItem.date ? moment(dayItem.date, 'YYYY-M-DD').format('D/M/YYYY') : 'N/a'}</td>
                    </tr>
                    <tr>
                        <td>Total Time:</td>
                        <td>{dayItem.time ? `${Math.round(secondsToMinutes(dayItem.time))} Minutes` : 'N/a'}</td>
                    </tr>
                    <tr>
                        <td>Max Strength:</td>
                        <td>{dayItem.maximumStimulationStrength ? `${dayItem.maximumStimulationStrength} Volts (V)` : 'N/a'}</td>
                    </tr>
                    <tr>
                        <td>Pulse Width:</td>
                        <td>{dayItem.maximumPulseWidth ? `${dayItem.maximumPulseWidth} Microseconds (μs)` : 'N/a'}</td>
                    </tr>
                </tbody>
            </table>
        );

        return (
            <Tooltip key={`day-item-${dayItem.day}`} placement="top" title={tooltipTable} color="#3F8CC8" overlayStyle={{ maxWidth: '500px' }}>
                <div className={`day-item${extraClass}`}>
                    {secondsToMinutes(dayItem.time) < 30 ? <div className="percentage-background" style={{ height: `${percentage}%` }} /> : null}
                    <span className="day-number">{dayItem.day}</span>
                </div>
            </Tooltip>
        );

    };

    return (
        <div className="daily-progress-block">
            <div className="days-listing-block">
                <div className="days-wrapper">
                    {days.map((dayItem) => (
                        renderDayItem(dayItem)
                    ))}
                </div>
            </div>

            <div className="daily-progress-legend">
                <div className="legend-title">
                    <div className="info-button"><InfoOutlined /></div> Hover over day for more information
                </div>
                <div className="thin-horizontal-line" />
                <table>
                    <tbody>
                        <tr>
                            <td><div className="circle-icon dark-green" aria-label="Dark Green Circle" /></td>
                            <td><span className="label">Dark Green:</span></td>
                            <td>Patient spent a minimum of 45 minutes training</td>
                        </tr>
                        <tr>
                            <td><div className="circle-icon light-green" aria-label="Light Green Circle" /></td>
                            <td><span className="label">Light Green:</span></td>
                            <td>Patient spent between 30 - 45 minutes training</td>
                        </tr>
                        <tr>
                            <td><div className="circle-icon grey" aria-label="Grey Circle" /></td>
                            <td><span className="label">Grey:</span></td>
                            <td>Patient performed less than 30 minutes of training. Progress bar gives an indication of time spent</td>
                        </tr>
                        <tr>
                            <td><div className="circle-icon white" aria-label="White Circle" /></td>
                            <td><span className="label">White:</span></td>
                            <td>Patient has not yet trained on this day</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

DailyProgressBlock.defaultProps = {
    days: [],
};

DailyProgressBlock.propTypes = {
    days: PropTypes.array,
};

export default DailyProgressBlock;
