import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_ALERTS_REQUEST: null,
    GET_ALERTS_SUCCESS: null,
    GET_ALERTS_FAILURE: null,

    FILTER_ALERTS_REQUEST: null,

    SET_FILTER_OPTIONS_REQUEST: null,

    SET_NAME_TAG_REQUEST: null,

    SET_PATIENT_ID_TAG_REQUEST: null,

    CREATE_ACTION_REQUEST: null,
    CREATE_ACTION_SUCCESS: null,
    CREATE_ACTION_FAILURE: null,

    CONVERT_TO_ACTION_REQUEST: null,
    CONVERT_TO_ACTION_SUCCESS: null,
    CONVERT_TO_ACTION_FAILURE: null,

    SET_DISPLAY_ALERTS_REQUEST: null,
});

export const {
    getAlertsRequest,
    getAlertsSuccess,
    getAlertsFailure,
} = createActions(
    constants.GET_ALERTS_REQUEST,
    constants.GET_ALERTS_SUCCESS,
    constants.GET_ALERTS_FAILURE,
);

export const {
    filterAlertsRequest,
} = createActions(
    constants.FILTER_ALERTS_REQUEST,
);

export const {
    setFilterOptionsRequest,
} = createActions(
    constants.SET_FILTER_OPTIONS_REQUEST,
);

export const {
    setNameTagRequest,
} = createActions(
    constants.SET_NAME_TAG_REQUEST,
);

export const {
    setPatientIdTagRequest,
} = createActions(
    constants.SET_PATIENT_ID_TAG_REQUEST,
);

export const {
    createActionRequest,
    createActionSuccess,
    createActionFailure,
} = createActions(
    constants.CREATE_ACTION_REQUEST,
    constants.CREATE_ACTION_SUCCESS,
    constants.CREATE_ACTION_FAILURE,
);

export const {
    convertToActionRequest,
    convertToActionSuccess,
    convertToActionFailure,
} = createActions(
    constants.CONVERT_TO_ACTION_REQUEST,
    constants.CONVERT_TO_ACTION_SUCCESS,
    constants.CONVERT_TO_ACTION_FAILURE,
);

export const {
    setDisplayAlertsRequest,
} = createActions(
    constants.SET_DISPLAY_ALERTS_REQUEST,
);
