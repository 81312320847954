import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth } from '../utils/api';
import history from '../utils/history';

import {
    registerSuccess,
    registerFailure,

    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetPasswordSuccess,
    resetPasswordFailure,

    createPasswordSuccess,
    createPasswordFailure,

    downloadActionsSuccess,
    downloadActionsFailure,

    updateUserSuccess,
    updateUserFailure,
} from './userActions';

import {
    getSingleClinicFromServerRequest,
} from '../clinic/clinicActions';

import {
    updatePatientRequestRequest,
} from '../patient/patientActions';

function downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
}

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    REGISTER_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    CREATE_PASSWORD_REQUEST,
    DOWNLOAD_ACTIONS_REQUEST,
    UPDATE_USER_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.token);
}

function* register({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/register',
            body: {
                email: payload.data.email,
                password: payload.data.password,
                first_name: payload.data.firstName,
                last_name: payload.data.lastName,
            },
        });
        history.push('/login');
        yield put(registerSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(registerFailure(e.response ? e.response.data.message : e));
    }
}

function* makeLoginRequest(action) {
    lockr.set('USER_ROLE', false);
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'login',
            body: {
                email: action.payload.email,
                password: action.payload.password,
            },
            headers: {
                'Content-Type': 'application/form-data',
            },
        });

        if (response.data.token) {
            yield call(saveSessionToken, response);
            yield put(loginSuccess(response.data));

            if (response.data?.user?.role_id === 5) {
                lockr.set('USER_ROLE', response.data?.user?.role_id);
                yield put(getSingleClinicFromServerRequest());
                history.push('/single-clinic');
            } else {
                history.push('/clinics');
            }
        } else {
            yield put(loginFailure('login request failed'));
        }
    } catch (e) {
        if (e?.response?.status === 401) {
            e.response.data.message = 'Invalid credentials. Please check your account details and try again.';
        }
        yield put(loginFailure(e.response ? e.response.data.message : e));
    }
}

function* logoutRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/logout',
            body: {},
        });
        lockr.rm(AUTH_KEY);
        lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
        localStorage.clear();
        yield put(logoutSuccess(response));
        history.push('/login');
    } catch (e) {
        yield put(logoutFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/forgot-password',
            body: {
                email: payload.email,
            },
        });

        yield put(forgotPasswordSuccess(response));
        message.success('A password reset email has been requested, if an account exists you will receive an email shortly.');
        history.push('/login');
    } catch (e) {
        yield put(forgotPasswordFailure(e.response ? e.response?.data?.errors?.email[0] : e));
    }
}

function* resetPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: `/reset-password/${payload.email}/${payload.token}`,
            body: {
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.passwordConfirmation,
                token: payload.token,
            },
        });

        yield put(resetPasswordSuccess(response));
        message.success('Password updated successfully');
        history.push('/login');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* createPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/user/create-password',
            body: {
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.passwordConfirmation,
                token: payload.token,
            },
        });

        yield put(createPasswordSuccess(response));
        if (response.data.failure) {
            message.error(response.data.failure);
        } else {
            message.success('Password updated successfully');
        }
        history.push('/login');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(createPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadActions({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            header: {
                'Content-Type': 'application/vnd.ms-excel',
            },
            method: 'POST',
            url: '/communication/download-actions',
            body: payload,
        });

        if (response?.data !== '' && response?.data?.success) {
            message.success(response?.data?.success);
            downloadURI(response?.data?.data, 'actions');
        }
        yield put(downloadActionsSuccess(response));
    } catch (e) {
        yield put(downloadActionsFailure(e.response ? e.response.data.message : e));
    }
}

function* updateUser({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user/updateB',
            body: payload,
        });

        message.success(response?.data?.success);
        yield put(updateUserSuccess(response));
        yield put(updatePatientRequestRequest(response?.data?.user));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateUserFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(REGISTER_REQUEST, register),
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
        takeEvery(CREATE_PASSWORD_REQUEST, createPassword),
        takeEvery(DOWNLOAD_ACTIONS_REQUEST, downloadActions),
        takeEvery(UPDATE_USER_REQUEST, updateUser),
    ]);
}
