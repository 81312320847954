import React from 'react';
import { Link } from 'react-router-dom';
// import { LeftOutlined } from '@ant-design/icons';

import Standard from '../../components/layouts/Standard';
import './dashboard.scss';

const Dashboard = () => {
    console.log('dashboard screen');
    return (
        <Standard>
            <div>
                <h1><Link to="/clinics">Clinics</Link></h1>
            </div>
        </Standard>
    );
};

export default Dashboard;

Dashboard.propTypes = {};
