/* eslint-disable quote-props */
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth, formatParams, makeParamString } from '../utils/api';

import {
    getClinicsSuccess,
    getClinicsFailure,

    getGeneralSummarySuccess,
    getGeneralSummaryFailure,

    getSingleClinicFromServerSuccess,
    getSingleClinicFromServerFailure,

    getClinicByIdFailure,
    getClinicByIdSuccess,
} from './clinicActions';

const {
    GET_CLINICS_REQUEST,
    GET_GENERAL_SUMMARY_REQUEST,
    GET_SINGLE_CLINIC_FROM_SERVER_REQUEST,
    GET_CLINIC_BY_ID_REQUEST,

} = require('./clinicActions').constants;

function* getClinics() {

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/clinic',
        });
        yield put(getClinicsSuccess(response));
    } catch (e) {
        // message.error(e.response ? e.response.data.message : e);
        yield put(getClinicsFailure(e.response ? e.response.data.message : e));
    }
}

function* getGeneralSummary() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/clinic/get-general-summary',
        });

        yield put(getGeneralSummarySuccess(response));
    } catch (e) {
        yield put(getGeneralSummaryFailure(e.response ? e.response.data.message : e));
    }
}

function* getSingleClinicFromServer() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/clinic/single-clinic',
        });

        yield put(getSingleClinicFromServerSuccess(response));
    } catch (e) {
        yield put(getSingleClinicFromServerFailure(e.response ? e.response.data.message : e));
    }
}

function* getClinicById({ payload }) {
    try {
        const params = makeParamString(formatParams(payload));
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `clinic/clinic-by-id${params}`,
        });

        yield put(getClinicByIdSuccess(response));
    } catch (e) {
        yield put(getClinicByIdFailure(e.response ? e.response.data.message : e));
    }
}

export default function* consultationsSaga() {
    yield* [
        takeEvery(GET_CLINICS_REQUEST, getClinics),
        takeEvery(GET_GENERAL_SUMMARY_REQUEST, getGeneralSummary),
        takeEvery(GET_SINGLE_CLINIC_FROM_SERVER_REQUEST, getSingleClinicFromServer),
        takeEvery(GET_CLINIC_BY_ID_REQUEST, getClinicById),
    ];
}
