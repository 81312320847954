import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import clinicSaga from './clinic/clinicSaga';
import alertSaga from './alert/alertSaga';
import patientSaga from './patient/patientSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        clinicSaga(),
        alertSaga(),
        patientSaga(),
    ]);
}
