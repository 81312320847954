import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select } from 'antd';

import {
    SORT_PATIENTS_BY_ASC_DATE,
    SORT_PATIENTS_BY_DESC_DATE,
    SORT_PATIENTS_BY_ASC_NUMERIC,
    SORT_PATIENTS_BY_DESC_NUMERIC,
} from '../../../core/utils/helpers';
import {
    filterPatientsRequest,
    sortPatientsRequest,
} from '../../../core/patient/patientActions';

import iconInventory from '../../../assets/images/profileIcons/iconInventory';

import './searchForm.scss';

const { Option } = Select;

const SearchForm = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const {
        allPatients,
    } = useSelector((state) => state.patient);

    const [searchTerm, setSearchTerm] = useState('');

    const tags = allPatients.map((patient) => ({
        ...patient,
        value: patient?.id,
        key: patient?.patient_id,
        label: patient?.patient_id,
    })).filter(val => val?.label?.includes(searchTerm))
        .sort((a, b) => a?.label.localeCompare(b?.label))
        .slice(0, 5);

    function handleOptionSelect(value) {
        dispatch(filterPatientsRequest({ id: value }));
        setSearchTerm('');
    }

    function handleOnClear() {
        dispatch(filterPatientsRequest({}));
        setSearchTerm('');
    }

    function handleSetSearchTerm(value) {
        setSearchTerm(value);
    }

    return (
        <Form
            form={form}
            className="patient-clinic-form"
            onFinish={() => console.log('on finish')}
            onFinishFailed={() => console.log('on finish failed')}
            layout="vertical">
            <Form.Item className="icon-item" name="search_key">
                <Select
                    onClear={handleOnClear}
                    id="patient-auto-complete-B"
                    placeholder="Search"
                    showSearch
                    allowClear
                    autoClearSearchValue
                    size="large"
                    style={{
                        width: '50%',
                        minWidth: 300,
                        fontSize: 18,
                        color: 'F4F9FC',
                    }}
                    onSearch={(value) => handleSetSearchTerm(value)}
                    onSelect={(value, option) => handleOptionSelect(value, option)}
                    value={searchTerm}
                    filterOption={false}
                    suffixIcon={null}>
                    {tags.map((tag) => {
                        const image = iconInventory.find((val) => val?.id === tag?.profile_icon_id);
                        return (
                            <Option key={tag?.value} value={tag?.value}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
                                    <>{image?.image ? <img style={{ height: 20, aspectRatio: 1, borderRadius: 10, border: '0.5px solid #DADADA' }} src={image?.image} alt="Profile Icon" /> : null}<div style={{ marginLeft: 5 }}>{tag?.label}</div></>
                                </div>
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                name="sort_by"
                className="custom-select">
                <Select style={{ float: 'left' }} placeholder="Sort by" onChange={(value) => { dispatch(sortPatientsRequest(value)); }}>
                    <Select.Option value={SORT_PATIENTS_BY_DESC_DATE}>Newest to Oldest</Select.Option>
                    <Select.Option value={SORT_PATIENTS_BY_ASC_DATE}>Oldest to Newest</Select.Option>
                    <Select.Option value={SORT_PATIENTS_BY_ASC_NUMERIC}>Ascending Numerically</Select.Option>
                    <Select.Option value={SORT_PATIENTS_BY_DESC_NUMERIC}>Descending Numerically</Select.Option>
                </Select>
            </Form.Item>
        </Form>
    );
};

export default SearchForm;
