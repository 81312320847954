/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import Helmet from 'react-helmet';

import Main from '../Main';
import './standard.scss';
import sensTrain from '../../../assets/images/sens-train.png';
import useUser from '../../../core/user/useUser';

const { Content } = Layout;

const StandardHeader = (props) => {
    const {
        backLink,
    } = props;

    const { logoutRequest } = useUser();
    const user = useSelector(state => state.user);

    const handleLogout = (e) => {
        e.preventDefault();
        logoutRequest();
    };

    return (
        <div className="header">
            <div className="content-wrapper">
                <img
                    alt="Sens Train"
                    src={sensTrain} />
                <Button
                    className="logout-button"
                    type="link"
                    ghost
                    shape="round"
                    icon={(
                        <LogoutOutlined style={{
                            display: 'flex',
                            alignItems: 'center',
                        }} />
                    )}
                    size="large"
                    loading={user.isFetching}
                    onClick={handleLogout}
                    style={{ padding: '0px' }}>
                    Log Out
                </Button>
            </div>
        </div>
    );
};

const StandardFooter = (props) => {
    const today = new Date();
    const year = today.getFullYear();

    return (
        <div className="footer">
            <p className="standard-footer-text">2PD {year}</p>
            <Link
                to="/forgotten-password">
                <u className="standard-footer-text">
                    Change Password
                </u>
            </Link>
        </div>
    );
};

const Standard = (props) => {
    const {
        children,
        backLink,
        title,
    } = props;

    return (
        <Main extraClass="every-page-content">
            <Helmet title={title} />
            <StandardHeader backLink={backLink} />
            <Content style={{ overflow: 'overlay' }}>
                {children}
            </Content>
            <StandardFooter />
        </Main>
    );
};

Standard.defaultProps = {
    backLink: null,
    children: false,
    title: 'Sens Train',
};

Standard.propTypes = {
    backLink: PropTypes.object,
    children: PropTypes.node,
    title: PropTypes.string,
};

export default Standard;
