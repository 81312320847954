import React from 'react';
import PropTypes from 'prop-types';

import './main.scss';

const Main = ({ children, extraClass }) => (
    <div className={`container main-wrapper ${extraClass}`}>
        {children}
    </div>
);

Main.propTypes = {
    children: PropTypes.node,
    extraClass: PropTypes.string,
};

Main.defaultProps = {
    children: null,
    extraClass: '',
};

export default Main;
