import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Button,
    Form,
    Tooltip,
} from 'antd';

import iconInventory from '../../assets/images/profileIcons/iconInventory';
import {
    updateUserRequest,
} from '../../core/user/userActions';

import './profileIconModalForm.scss';
import { InfoOutlined } from '../icons';

const ProfileIconModalForm = ({ patientId }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { allPatients } = useSelector((state) => state.patient);
    const patient = allPatients.find((val) => val?.id === patientId);

    const [chosenIcon, setChosenIcon] = useState(patient?.profile_icon_id);
    const [hoverIcon, setHoverIcon] = useState(null);

    function handleSetChosenIcon(value) {
        setChosenIcon(value);
    }

    function handleSave() {
        dispatch(updateUserRequest({ ...patient, profile_icon_id: chosenIcon }));
    }

    return (
        <Form
            form={form}
            style={{
                padding: 30,
                minWidth: '100%',
            }}
            className="profile-icon-form"
            layout="vertical">
            <div style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
            }}>
                <h1 className="page-title">Select Profile Image</h1>
                <Tooltip title="Please add a profile image for your patient to help you identify them in your patient list." color="#3F8CC8">
                    <div style={{ margin: 10 }} className="info-button"><InfoOutlined /></div>
                </Tooltip>
            </div>
            <div className="horizontal-line" />
            <Form.Item>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        backgroundColor: '#F4F9FC',
                        width: 910,
                        maxWidth: '100%',
                    }}>
                    {
                        iconInventory.map((val) => (
                            <button
                                type="button"
                                style={{
                                    height: 90,
                                    width: 90,
                                    margin: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderWidth: [chosenIcon, hoverIcon].includes(val?.id) ? 7 : 0,
                                    borderColor: hoverIcon === val?.id ? '#D3D3D3' : '#3F8CC8',
                                    borderRadius: 8,
                                    borderStyle: 'solid',
                                }}
                                onClick={() => handleSetChosenIcon(val.id)}
                                onMouseEnter={() => setHoverIcon(val.id)}
                                onMouseLeave={() => setHoverIcon(null)}>
                                <img
                                    alt="Profile Icon"
                                    src={val?.image}
                                    style={{
                                        height: 60,
                                        width: 60,
                                        // marginTop: 15,
                                    }} />
                            </button>
                        ))
                    }
                </div>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0, display: 'flex', justifyContent: 'center' }}>
                <Button
                    className="large-button"
                    style={{
                        minWidth: '60%',
                        width: 550,
                        maxWidth: '100%',
                    }}
                    size="large"
                    onClick={() => handleSave()}
                    // disabled={exportType === 2 && selectedTags?.length === 0}
                    type="primary">
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
};

ProfileIconModalForm.defaultProps = {
    patientId: null,
};

ProfileIconModalForm.propTypes = {
    patientId: PropTypes.number,
};

export default ProfileIconModalForm;
