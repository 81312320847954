/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import lockr from 'lockr';

import {
    CoreRouter,
    LogoutRoute,
    DefaultRoute,
    AuthRoute,
} from './utils/coreRouter';
import Dashboard from '../pages/Dashboard';
import CreatePassword from '../pages/CreatePassword';
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import ForgottenPassword from '../pages/ForgottenPassword';
import Clinics from '../pages/Clinics';
import SingleClinic from '../pages/SingleClinic';
import SinglePatient from '../pages/SinglePatient';

const Routes = ({ persistor }) => (
    <CoreRouter>
        <DefaultRoute exact path="/" component={Login} />
        <DefaultRoute exact path="/login" component={Login} />
        <DefaultRoute exact path="/create-password/:token/:email" component={CreatePassword} />
        <DefaultRoute exact path="/reset-password/:token/:email" component={ResetPassword} />
        <DefaultRoute exact path="/forgotten-password" component={ForgottenPassword} />
        {/* <DefaultRoute exact path="/reset-password/:token/:email" component={ResetPassword} /> */}
        <AuthRoute exact path="/dashboard" component={Dashboard} />
        <AuthRoute exact path="/clinics" component={Clinics} allowedOnlyRoles={['admin', 'scorchsoft']} />
        <AuthRoute exact path="/single-clinic" component={SingleClinic} />
        <AuthRoute exact path="/single-patient" component={SinglePatient} />
        <LogoutRoute exact path="/logout" persistor={persistor} />
    </CoreRouter>
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;
