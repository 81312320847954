import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, size, findIndex } from 'underscore';
import dayjs from 'dayjs';
import { Form, Input, Col, Row, Button, Select, DatePicker } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
import Tag from '../Tag';

import { setNameTagRequest, setPatientIdTagRequest } from '../../../core/alert/alertActions';

import './addActionForm.scss';

const AddActionForm = ({ createAction, singleNote, defaultPatientId }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { allPatients } = useSelector((state) => state.patient);
    const { nameTag, patientIdTag } = useSelector((state) => state.alert);
    let loadData = {};

    const [cptCode, setCptCode] = useState();
    const [cptDescription, setCptDescription] = useState();
    const [patientId, setPatientId] = useState(defaultPatientId);
    const [date, setDate] = useState();
    const [notes, setNotes] = useState();

    useEffect(() => {
        setPatientId(defaultPatientId);
        form.setFieldValue('patient_id', defaultPatientId);
    }, [defaultPatientId]);

    useEffect(() => {
        if (!isEmpty(singleNote)) {
            loadData = { ...singleNote };
            if (!isEmpty(singleNote)) {
                loadData.patient_id = singleNote?.user?.patient_id;
                loadData.cpt_description = [singleNote.cpt_description];

                if (!isEmpty(singleNote.date)) {
                    loadData.date = dayjs(singleNote.date, 'YYYY-MM-DD HH:mm:ss');
                } else {
                    loadData.date = dayjs();
                }
            }

            form.setFieldsValue(loadData);
            setCptCode(loadData?.cpt_code);
            setCptDescription(loadData?.cpt_description);
            setPatientId(loadData?.patient_id);
            setNotes(loadData?.notes);
            setDate(loadData?.date?.format('YYYY-MM-DD 00:00:00'));
        }
    }, [singleNote]);

    const validateForm = () => {
        let isFormValid = true;

        if (!cptCode || cptCode === '') {
            isFormValid = false;
        }

        if (!cptDescription || cptDescription === '') {
            isFormValid = false;
        }

        if (!patientId || patientId === '') {
            isFormValid = false;
        }

        if (!date || date === '') {
            isFormValid = false;
        }

        return isFormValid;
    };

    const options = [
        {
            value: 'Physical Therapy Evaluation',
            label: 'Physical Therapy Evaluation',
            code: '97162',
        },
        {
            value: 'Re-Evaluation of PT Plan of Care',
            label: 'Re-Evaluation of PT Plan of Care',
            code: '97164',
        },
        {
            value: 'Sensory Integration',
            label: 'Sensory Integration',
            code: '97533',
        },
        {
            value: 'Telehealth Visit',
            label: 'Telehealth Visit',
            code: '97111',
        },
        {
            value: 'Neuromuscular Re-Education',
            label: 'Neuromuscular Re-Education',
            code: '97112',
        },
    ];

    const patientsForSelect = allPatients.map((patient) => ({
        value: patient.patient_id,
        label: patient.patient_id,
    }));

    const changeCptDescription = (cptValue) => {
        if (size(cptValue) > 1) {
            cptValue.shift();
        }
        setCptDescription(cptValue);

        const optionIndex = findIndex(options, (val) => val.value === cptValue[0]);
        if (optionIndex !== -1) {
            setCptCode(options[optionIndex].code);
            form.setFieldsValue({ cpt_code: options[optionIndex].code });
        }
    };

    const submitForm = () => {
        createAction({
            id: singleNote?.id,
            cpt_code: cptCode,
            cpt_description: cptDescription[0],
            patient_id: patientId,
            date,
            notes,
        });

        setTimeout(() => {
            form.resetFields();
            setCptCode('');
            setCptDescription('');
            setPatientId('');
            setNotes('');
            setDate('');
        }, 200);
    };

    return (
        <Form
            initialValues={loadData}
            form={form}
            className="add-action-form"
            onFinish={() => console.log('on finish')}
            onFinishFailed={() => console.log('on finish failed')}
            layout="vertical">
            <h1 className="page-title">{singleNote?.id ? 'Update' : 'Add'} Action</h1>
            <div className="horizontal-line" />
            <Row>
                <Col xs={2} sm={4} md={6} lg={8} xl={12} className="left-col">
                    <Form.Item
                        label="CPT Code *"
                        name="cpt_code">
                        <Input placeholder="*****" onChange={(e) => setCptCode(e.target.value)} />
                    </Form.Item>
                </Col>
                <Col xs={2} sm={4} md={6} lg={8} xl={12} className="right-col">
                    <Form.Item
                        label="CPT Description *"
                        name="cpt_description">
                        <Select
                            mode="tags"
                            showArrow={false}
                            filterOption={false}
                            notFoundContent={null}
                            placeholder="Enter CPT Description"
                            options={options}
                            onChange={(cptValue) => changeCptDescription(cptValue)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={2} sm={4} md={6} lg={8} xl={12} className="left-col">
                    <Form.Item
                        label="Patient ID *"
                        name="patient_id">
                        <Select
                            placeholder="*****"
                            options={patientsForSelect}
                            onChange={(optionPatientId) => setPatientId(optionPatientId)} />
                    </Form.Item>
                </Col>
                <Col xs={2} sm={4} md={6} lg={8} xl={12} className="right-col">
                    <Form.Item
                        label="Date *"
                        name="date">
                        <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(e) => setDate(e?.format('YYYY-MM-DD 00:00:00'))} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                        label="Notes"
                        name="notes">
                        <Input.TextArea
                            showCount
                            maxLength={200}
                            placeholder="Add any additional notes here"
                            onChange={(e) => setNotes(e.target.value)} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="button-col">
                    <Button
                        className="large-button"
                        size="large"
                        disabled={!validateForm()}
                        type="primary"
                        onClick={() => submitForm()}>Save
                    </Button>
                </Col>
            </Row>
            <div className="tag-row">
                {!isEmpty(nameTag) ? <Tag title={`Assigned to: ${nameTag}`} onClose={() => dispatch(setNameTagRequest(''))} /> : null}
                {!isEmpty(patientIdTag) ? <Tag title={`Patient ID: ${patientIdTag}`} onClose={() => dispatch(setPatientIdTagRequest(''))} /> : null}
            </div>
        </Form>
    );
};

AddActionForm.defaultProps = {
    createAction: () => {},
    singleNote: {},
    defaultPatientId: null,
};

AddActionForm.propTypes = {
    createAction: PropTypes.func,
    singleNote: PropTypes.object,
    defaultPatientId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default AddActionForm;
