import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Button, Spin, Empty, Modal } from 'antd';
import { PlusOutlined, FileTextOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'underscore';
import SearchForm from './SearchForm';
import AddActionForm from './AddActionForm';
import ExportActionForm from './ExportActionForm';
import Tag from './Tag';
import './alertsActionsBlock.scss';
import history from '../../core/utils/history';
import { getAlertsRequest, setNameTagRequest, setPatientIdTagRequest, setDisplayAlertsRequest, createActionRequest } from '../../core/alert/alertActions';
import { setSinglePatientRequest } from '../../core/patient/patientActions';

const AlertsActionsBlock = ({ clinicId, userId }) => {
    const dispatch = useDispatch();
    const { allPatients } = useSelector((state) => state.patient);

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [selectedNoteId, setSelectedNoteId] = useState(null);
    const [singleNote, setSingleNote] = useState(null);

    useEffect(() => {
        const params = {};
        if (clinicId !== null) {
            params.clinic_id = clinicId;
        }

        if (userId !== null) {
            params.user_id = userId;
        }

        dispatch(getAlertsRequest(params));
    }, []);

    const { displayAlerts } = useSelector((state) => state.alert);
    const { isFetching } = useSelector((state) => state.alert);

    function filterAlerts(alerts) {
        const alerts2 = alerts.filter((alert) => {
            if (clinicId && clinicId !== alert?.user?.clinic_id) {
                return false;
            }

            if (userId && userId !== alert?.user?.id) {
                return false;
            }

            return true;
        });
        return alerts2;
    }

    useEffect(() => {
        const alerts = filterAlerts(displayAlerts);
        dispatch(setDisplayAlertsRequest(alerts));
    }, [displayAlerts?.length]);

    const openPatient = (patientId) => {
        dispatch(setSinglePatientRequest(patientId));
        history.push('single-patient');
    };

    const createUpdateAction = (data) => {
        const sendData = { ...data, ...{ id: selectedNoteId } };
        sendData.clinic_id = clinicId;
        dispatch(createActionRequest(sendData));
        setIsAddModalOpen(false);
        setIsNotesModalOpen(false);
        setSelectedPatientId(null);
        setSelectedNoteId(null);
    };

    const openSingleActionNotes = (selectedNote) => {
        setSingleNote(selectedNote);
        setSelectedNoteId(selectedNote?.id);
        setIsNotesModalOpen(true);
    };

    const convertToAction = (selectedNote) => {
        setSelectedPatientId(selectedNote?.user?.patient_id);
        setSelectedNoteId(selectedNote?.id);
        setIsAddModalOpen(true);
    };

    const renderAlert = (alert) => {
        const isAction = alert.type === 1;
        let extraAlertClass = '';

        if (alert.alert_type === 2) {
            extraAlertClass = ' warning';
        }

        if (alert.alert_type === 3) {
            extraAlertClass = ' danger';
        }

        let actionsBlock = '';

        if (!isAction) {
            actionsBlock = (
                <Col xs={2} sm={4} md={6} lg={8} xl={10} className="last-alert-col">
                    <Button
                        className="medium-button"
                        size="large"
                        type="primary"
                        onClick={() => openPatient(alert?.user_id)}>View Profile
                    </Button>
                    <Button
                        className="medium-button"
                        size="large"
                        type="secondary"
                        onClick={() => convertToAction(alert)}>Convert to Action
                    </Button>
                </Col>
            );
        } else if (!alert.notes || alert.notes === '') {
            actionsBlock = (
                <Col xs={2} sm={4} md={6} lg={8} xl={10} className="last-alert-col">
                    <Button
                        className="medium-button"
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => openSingleActionNotes(alert)}>Add Notes
                    </Button>
                </Col>
            );
        } else {
            actionsBlock = (
                <Col xs={2} sm={4} md={6} lg={8} xl={10} className="last-alert-col">
                    <Button
                        className="medium-button"
                        size="large"
                        type="primary"
                        icon={<FileTextOutlined />}
                        onClick={() => openSingleActionNotes(alert)}>View Notes
                    </Button>
                </Col>
            );
        }

        return (
            <div className={`alert-item${isAction ? ' action' : ''}`} key={`alert_item-${alert.id}`}>
                <div className="tag-row">
                    <Tag title={`Assigned to: ${alert?.user?.clinicianFullName}`} onClick={() => dispatch(setNameTagRequest(alert?.user?.clinicianFullName))} remove={false} />
                    <Tag title={`Patient ID: ${alert?.user?.patient_id}`} onClick={() => dispatch(setPatientIdTagRequest(alert?.user?.patient_id))} remove={false} />
                </div>
                <Row>
                    {isAction
                        ? (
                            <Col xs={2} sm={4} md={6} lg={8} xl={14} className="status-col">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>CPT {alert.cpt_code}:</td>
                                            <td>{alert.cpt_description}</td>
                                        </tr>
                                        <tr>
                                            <td>Date:</td>
                                            <td>{moment(alert.date).format('D/M/YYYY')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        )
                        : (
                            <Col xs={2} sm={4} md={6} lg={8} xl={14} className="status-col">
                                <div className={`circle-icon${extraAlertClass}`} />
                                <p>{alert.name}</p>
                            </Col>
                        )}
                    {actionsBlock}
                </Row>
            </div>
        );
    };

    return (
        <Spin tip="Loading" size="small" spinning={isFetching}>
            <div className="alerts-and-actions-block">
                <div className="alerts-form-block">
                    <SearchForm
                        addAction={() => {
                            setIsAddModalOpen(true);
                            if (userId) {
                                const patient = allPatients.find(p => p?.id === userId);
                                setSelectedPatientId(patient?.patient_id);
                            }
                        }}
                        exportAction={() => setIsExportModalOpen(true)} />
                </div>
                <div className="alerts-content-block">
                    {
                        !isEmpty(displayAlerts) && Array.isArray(displayAlerts)
                        // eslint-disable-next-line
                        ? displayAlerts.map(alert => (
                                renderAlert(alert)
                            ))
                            : <Empty description="No Results Found" />
                    }
                </div>
            </div>
            <Modal
                footer={null}
                onCancel={() => {
                    setIsAddModalOpen(false);
                    setSelectedPatientId(null);
                }}
                width={1000}
                forceRender
                open={isAddModalOpen}>
                <AddActionForm createAction={(data) => createUpdateAction(data)} defaultPatientId={selectedPatientId} />
            </Modal>

            <Modal
                footer={null}
                onCancel={() => {
                    setIsNotesModalOpen(false);
                    setSelectedNoteId(null);
                }}
                width={1000}
                forceRender
                open={isNotesModalOpen}>
                <AddActionForm singleNote={singleNote} createAction={(data) => createUpdateAction(data)} />
            </Modal>
            {isExportModalOpen
                && (
                    <Modal
                        footer={null}
                        onCancel={() => setIsExportModalOpen(false)}
                        width={1000}
                        open={isExportModalOpen}>
                        <ExportActionForm clinicId={clinicId} userId={userId} />
                    </Modal>
                )}
        </Spin>
    );
};

AlertsActionsBlock.defaultProps = {
    clinicId: null,
    userId: null,
};

AlertsActionsBlock.propTypes = {
    clinicId: PropTypes.number,
    userId: PropTypes.number,
};

export default AlertsActionsBlock;
