import React, { useEffect } from 'react';

// import { Link } from 'react-router-dom';
// import { InfoOutlined } from '@ant-design/icons';
// import { Table, Button, Tooltip } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import Standard from '../../components/layouts/Standard';
import SummaryBlock from '../../components/SummaryBlock';
import AlertsActionsBlock from '../../components/AlertsActionsBlock';
import PatientsBlock from '../../components/PatientsBlock';
import {
    ClockCircleIconGreen,
    WarningIconOrange,
    UrgentIconRed,
} from '../../assets/icons';

import { getClinicByIdRequest, getClinicsRequest, getGeneralSummaryRequest } from '../../core/clinic/clinicActions';
import { getPatientsRequest } from '../../core/patient/patientActions';

import './singleClinic.scss';

const SingleClinic = () => {

    const dispatch = useDispatch();
    const { singleClinic } = useSelector((state) => state.clinic);

    useEffect(() => {
        dispatch(getClinicsRequest());
        dispatch(getGeneralSummaryRequest());
        dispatch(getPatientsRequest({ clinic_id: singleClinic?.id }));
    }, [singleClinic]);

    useEffect(() => {
        if (singleClinic?.id) {
            dispatch(getClinicByIdRequest({ id: singleClinic?.id }));
        }
    }, []);

    return (
        <Standard title={`Clinic - ${singleClinic?.name}`}>
            <div>
                <h1 className="page-title">Dashboard</h1>
                <div className="horizontal-line" />
                <SummaryBlock
                    successBlockTitle="Currently in Therapy"
                    warningBlockTitle="Actions Required"
                    dangerBlockTitle="Urgent Actions Needed"
                    successBlockNumber={singleClinic?.numberPatientsInTherapy}
                    warningBlockNumber={singleClinic?.numberPatientActions}
                    dangerBlockNumber={singleClinic?.numberPatientUrgentActions}
                    successBlockIcon={<ClockCircleIconGreen />}
                    warningBlockIcon={<WarningIconOrange />}
                    dangerBlockIcon={<UrgentIconRed />} />

                <h2 className="section-title">Alerts & Actions</h2>

                {singleClinic?.id ? <AlertsActionsBlock clinicId={singleClinic?.id} /> : null}

                <h2 className="section-title">Patients</h2>

                <PatientsBlock />
            </div>
        </Standard>
    );
};

export default SingleClinic;

SingleClinic.propTypes = {};
