import React from 'react';
import PropTypes from 'prop-types';

import { TickCircleIcon, ClockCircleIcon, DangerCircleIcon } from '../../assets/icons';

import './summaryBlock.scss';

const SummaryBlock = ({
    successBlockTitle,
    warningBlockTitle,
    dangerBlockTitle,
    successBlockNumber,
    warningBlockNumber,
    dangerBlockNumber,
    successBlockIcon,
    warningBlockIcon,
    dangerBlockIcon,
}) => (
    <div className="summary-block">
        <div className="summary-item">
            <div className="content-wrapper">
                <div className="left-side">
                    {successBlockIcon}
                </div>
                <div className="right-side">
                    <p className="number">{successBlockNumber}</p>
                    <p className="subtitle">{successBlockTitle}</p>
                </div>
            </div>
        </div>
        <div className="summary-item warning">
            <div className="content-wrapper">
                <div className="left-side">
                    {warningBlockIcon}
                </div>
                <div className="right-side">
                    <p className="number">{warningBlockNumber}</p>
                    <p className="subtitle">{warningBlockTitle}</p>
                </div>
            </div>
        </div>
        <div className="summary-item danger">
            <div className="content-wrapper">
                <div className="left-side">
                    {dangerBlockIcon}
                </div>
                <div className="right-side">
                    <p className="number">{dangerBlockNumber}</p>
                    <p className="subtitle">{dangerBlockTitle}</p>
                </div>
            </div>
        </div>
    </div>
);

SummaryBlock.defaultProps = {
    successBlockTitle: 'Patients Completed',
    warningBlockTitle: 'Patients in Progress',
    dangerBlockTitle: 'Patients Requiring Action',
    successBlockNumber: 0,
    warningBlockNumber: 0,
    dangerBlockNumber: 0,
    successBlockIcon: <TickCircleIcon />,
    warningBlockIcon: <ClockCircleIcon />,
    dangerBlockIcon: <DangerCircleIcon />,
};

SummaryBlock.propTypes = {
    successBlockTitle: PropTypes.string,
    warningBlockTitle: PropTypes.string,
    dangerBlockTitle: PropTypes.string,
    successBlockNumber: PropTypes.number,
    warningBlockNumber: PropTypes.number,
    dangerBlockNumber: PropTypes.number,
    successBlockIcon: PropTypes.object,
    warningBlockIcon: PropTypes.object,
    dangerBlockIcon: PropTypes.object,
};

export default SummaryBlock;
