const SORT_BY_NEWEST = 1;
const SORT_BY_OLDEST = 2;
const SORT_BY_ALPHABETIC = 3;

const SORT_PATIENTS_BY_ASC_DATE = 1;
const SORT_PATIENTS_BY_DESC_DATE = 2;
const SORT_PATIENTS_BY_ASC_NUMERIC = 3;
const SORT_PATIENTS_BY_DESC_NUMERIC = 4;

export {
    // Constants
    SORT_BY_NEWEST,
    SORT_BY_OLDEST,
    SORT_BY_ALPHABETIC,

    SORT_PATIENTS_BY_ASC_DATE,
    SORT_PATIENTS_BY_DESC_DATE,
    SORT_PATIENTS_BY_ASC_NUMERIC,
    SORT_PATIENTS_BY_DESC_NUMERIC,
};
