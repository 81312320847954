import { combineReducers } from 'redux';
import { message } from 'antd';
import global from './global/globalReducer';
import user from './user/userReducer';
import clinic from './clinic/clinicReducer';
import alert from './alert/alertReducer';
import patient from './patient/patientReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    clinic,
    alert,
    patient,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1) {
        message.error(action.payload);
    }

    return appReducers(state, action);
};

export default rootReducer;
