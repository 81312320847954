/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form,
    Input,
    Button,
} from 'antd';
import { Link } from 'react-router-dom';

import './login.scss';
import { resetUserFetchingRequest } from '../../core/user/userActions';
import Authentication from '../../components/layouts/Authentication';
import useUser from '../../core/user/useUser';
// import history from '../../core/utils/history';

const Login = () => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const {
        loginRequest,
    } = useUser();

    useEffect(() => {
        // history.push('/login');
        dispatch(resetUserFetchingRequest());
    }, []);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        loginRequest({
            email: values.email_address,
            password: values.password,
        });
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <Authentication>
            <div
                className="ui container login-wrapper"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                        width: '90%',
                        maxWidth: 550,
                    }}
                    layout="vertical">
                    <p
                        className="authentication-title">Login
                    </p>
                    <div className="horizontal-line" />
                    <Form.Item
                        className="authentication-form-item"
                        label="Email Address"
                        name="email_address"
                        rules={[{
                            required: true,
                            message: 'Please enter your email address',
                        }]}>
                        <Input placeholder="Enter email address" />
                    </Form.Item>
                    <Form.Item
                        className="authentication-form-item"
                        style={{
                            marginBottom: '50px',
                        }}
                        label="Password"
                        name="password"
                        rules={[{
                            required: true,
                            message: 'Please enter your password',
                        }]}>
                        <Input.Password placeholder="Enter password" />
                    </Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="authentication-submit"
                        loading={user.isFetching}>
                        Login
                    </Button>
                    <Link
                        to="/forgotten-password" // Should trigger backend functionality.
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 10,
                        }}>
                        <u className="authentication-submit-subtext">Forgot password?
                        </u>
                    </Link>
                </Form>
            </div>
        </Authentication>
    );
};

export default Login;
