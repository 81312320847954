import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    REGISTER_REQUEST: null,
    REGISTER_SUCCESS: null,
    REGISTER_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    SAVE_USER_DATA_REQUEST: null,

    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,

    CREATE_PASSWORD_REQUEST: null,
    CREATE_PASSWORD_SUCCESS: null,
    CREATE_PASSWORD_FAILURE: null,

    RESET_USER_FETCHING_REQUEST: null,

    DOWNLOAD_ACTIONS_REQUEST: null,
    DOWNLOAD_ACTIONS_SUCCESS: null,
    DOWNLOAD_ACTIONS_FAILURE: null,

    UPDATE_USER_REQUEST: null,
    UPDATE_USER_SUCCESS: null,
    UPDATE_USER_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,

    saveTokenRequest,
    saveTokenSuccess,

    loginRequest,
    loginSuccess,
    loginFailure,

    registerRequest,
    registerSuccess,
    registerFailure,

    logoutRequest,
    logoutSuccess,
    logoutFailure,

    saveUserDataRequest,

    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,

    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,

    createPasswordRequest,
    createPasswordSuccess,
    createPasswordFailure,

    resetUserFetchingRequest,

    downloadActionsRequest,
    downloadActionsSuccess,
    downloadActionsFailure,

    updateUserRequest,
    updateUserSuccess,
    updateUserFailure,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,

    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,

    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,

    constants.REGISTER_REQUEST,
    constants.REGISTER_SUCCESS,
    constants.REGISTER_FAILURE,

    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,

    constants.SAVE_USER_DATA_REQUEST,

    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE,

    constants.FORGOT_PASSWORD_REQUEST,
    constants.FORGOT_PASSWORD_SUCCESS,
    constants.FORGOT_PASSWORD_FAILURE,

    constants.CREATE_PASSWORD_REQUEST,
    constants.CREATE_PASSWORD_SUCCESS,
    constants.CREATE_PASSWORD_FAILURE,

    constants.RESET_USER_FETCHING_REQUEST,

    constants.DOWNLOAD_ACTIONS_REQUEST,
    constants.DOWNLOAD_ACTIONS_SUCCESS,
    constants.DOWNLOAD_ACTIONS_FAILURE,

    constants.UPDATE_USER_REQUEST,
    constants.UPDATE_USER_SUCCESS,
    constants.UPDATE_USER_FAILURE,
);
