import { Record } from 'immutable';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    SAVE_USER_DATA_REQUEST,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,

    CREATE_PASSWORD_REQUEST,
    CREATE_PASSWORD_SUCCESS,
    CREATE_PASSWORD_FAILURE,

    RESET_USER_FETCHING_REQUEST,

    DOWNLOAD_ACTIONS_REQUEST,
    DOWNLOAD_ACTIONS_SUCCESS,
    DOWNLOAD_ACTIONS_FAILURE,

    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    userData: {},
    token: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case LOGIN_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case CREATE_PASSWORD_REQUEST:
    case DOWNLOAD_ACTIONS_REQUEST:
    case UPDATE_USER_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS: {
        return state.set('isFetching', false)
            .set('userData', (payload && payload?.user ? payload?.user : {}))
            .set('token', (payload && payload?.token ? payload?.token : ''))
            .set('isLoggedIn', true);
    }

    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', {})
            .set('isLoggedIn', false);

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);

    case REGISTER_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case REGISTER_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', (payload && payload.user ? payload.user : {}));

    case SAVE_USER_DATA_REQUEST: {
        const { userData } = state;

        return state.set('isFetching', false)
            .set('userData', (payload && payload.data && payload.data.user ? payload.data.user : userData));
    }

    case RESET_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case CREATE_PASSWORD_SUCCESS:
    case RESET_USER_FETCHING_REQUEST:
        return state.set('isFetching', false);

    case DOWNLOAD_ACTIONS_SUCCESS:
        return state.set('isFetching', false);

    case UPDATE_USER_SUCCESS:
        return state.set('isFetching', false);

    case REGISTER_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case CREATE_PASSWORD_FAILURE:
    case DOWNLOAD_ACTIONS_FAILURE:
    case UPDATE_USER_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}
