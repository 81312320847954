import { Record } from 'immutable';
import { filter, isEmpty } from 'underscore';

const {
    GET_ALERTS_REQUEST,
    GET_ALERTS_SUCCESS,
    GET_ALERTS_FAILURE,

    FILTER_ALERTS_REQUEST,

    SET_FILTER_OPTIONS_REQUEST,

    SET_NAME_TAG_REQUEST,

    SET_PATIENT_ID_TAG_REQUEST,

    CREATE_ACTION_REQUEST,
    CREATE_ACTION_SUCCESS,
    CREATE_ACTION_FAILURE,

    CONVERT_TO_ACTION_REQUEST,
    CONVERT_TO_ACTION_SUCCESS,
    CONVERT_TO_ACTION_FAILURE,

    SET_DISPLAY_ALERTS_REQUEST,
} = require('./alertActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
    allAlerts: [],
    displayAlerts: [],
    onlyAlerts: true,
    onlyActions: true,
    searchKeyword: '',
    nameTag: '',
    patientIdTag: '',
});

const initialState = new InitialState();

export default function consultationReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    const applyOptionFilter = (displayAlerts, onlyAlerts, onlyActions) => {
        let returnAlerts = [];

        if (onlyAlerts && onlyActions) {
            returnAlerts = displayAlerts;
        } else {
            if (onlyAlerts) {
                const filteredAlerts = filter(displayAlerts, (str) => {
                    if (str.type === 2) return str; return '';
                });

                returnAlerts = returnAlerts.concat(filteredAlerts);
            }

            if (onlyActions) {
                const filteredActions = filter(displayAlerts, (str) => {
                    if (str.type === 1) return str; return '';
                });

                returnAlerts = returnAlerts.concat(filteredActions);
            }
        }

        return returnAlerts;
    };

    const applyTagFilter = (allAlerts, nameTag, patientIdTag) => {
        let returnAlerts = allAlerts;

        if (!isEmpty(nameTag)) {
            returnAlerts = filter(allAlerts, (str) => {
                if (str?.user?.fullName === nameTag) return str; return '';
            });
        }

        if (!isEmpty(patientIdTag)) {
            returnAlerts = filter(allAlerts, (str) => {
                if (str?.user?.patient_id === patientIdTag) return str; return '';
            });
        }

        return returnAlerts;
    };

    const applyKeyFilter = (allAlerts, searchKeyword) => {
        let displayAlerts = allAlerts;
        if (!isEmpty(searchKeyword)) {
            displayAlerts = filter(allAlerts, (str) => {
                const alertString = `${str.name} ${str.cpt_code} ${str.cpt_description} ${str?.user?.clinicianFullName} ${str?.user?.patient_id}`;
                if (alertString.toLowerCase().indexOf(searchKeyword.toLowerCase()) !== -1) return str; return '';
            });
        }

        return displayAlerts;
    };

    const applyAllFilters = ({ allAlerts, nameTag, patientIdTag, onlyAlerts, onlyActions, searchKeyword }) => {
        let displayAlerts = applyTagFilter(allAlerts, nameTag, patientIdTag);
        displayAlerts = applyOptionFilter(displayAlerts, onlyAlerts, onlyActions);
        displayAlerts = applyKeyFilter(displayAlerts, searchKeyword);

        return isEmpty(displayAlerts) ? [] : displayAlerts;
    };

    switch (type) {
    case SET_DISPLAY_ALERTS_REQUEST:
        return state.set('displayAlerts', payload);

    case GET_ALERTS_REQUEST:
    case CREATE_ACTION_REQUEST:
    case CONVERT_TO_ACTION_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_ALERTS_SUCCESS:
    case CREATE_ACTION_SUCCESS:
    case CONVERT_TO_ACTION_SUCCESS: {
        return state.set('isFetching', false)
            .set('allAlerts', payload.data)
            .set('displayAlerts', payload.data)
            .set('onlyAlerts', true)
            .set('onlyActions', true)
            .set('searchKeyword', '')
            .set('nameTag', '')
            .set('patientIdTag', '');
    }

    case FILTER_ALERTS_REQUEST: {
        const { allAlerts, patientIdTag, nameTag, onlyAlerts, onlyActions } = state;

        const displayAlerts = applyAllFilters({ allAlerts, nameTag, patientIdTag, onlyAlerts, onlyActions, searchKeyword: payload });

        return state.set('displayAlerts', displayAlerts)
            .set('searchKeyword', payload);
    }

    case SET_FILTER_OPTIONS_REQUEST: {
        const { allAlerts, searchKeyword, nameTag, patientIdTag } = state;
        let { onlyAlerts, onlyActions } = state;

        if (payload.option === 'onlyAlerts') {
            onlyAlerts = payload.value;
        }

        if (payload.option === 'onlyActions') {
            onlyActions = payload.value;
        }

        const displayAlerts = applyAllFilters({ allAlerts, nameTag, patientIdTag, onlyAlerts, onlyActions, searchKeyword });

        return state.set('onlyAlerts', onlyAlerts)
            .set('onlyActions', onlyActions)
            .set('displayAlerts', displayAlerts);
    }

    case SET_NAME_TAG_REQUEST: {
        const { allAlerts, searchKeyword, patientIdTag, onlyAlerts, onlyActions } = state;

        const displayAlerts = applyAllFilters({ allAlerts, nameTag: payload, patientIdTag, onlyAlerts, onlyActions, searchKeyword });

        return state.set('nameTag', payload)
            .set('displayAlerts', displayAlerts);
    }

    case SET_PATIENT_ID_TAG_REQUEST: {
        const { allAlerts, searchKeyword, nameTag, onlyAlerts, onlyActions } = state;

        const displayAlerts = applyAllFilters({ allAlerts, nameTag, patientIdTag: payload, onlyAlerts, onlyActions, searchKeyword });

        return state.set('patientIdTag', payload)
            .set('displayAlerts', displayAlerts);
    }

    case GET_ALERTS_FAILURE:
    case CREATE_ACTION_FAILURE:
    case CONVERT_TO_ACTION_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}
