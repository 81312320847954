import alligator from './icons8-alligator-100.png';
import badger from './icons8-badger-100.png';
import bear from './icons8-bear-100.png';
import bee from './icons8-bee-100.png';
import blackCat from './icons8-black-cat-100.png';
import bull from './icons8-bull-100.png';
import butterflySideView from './icons8-butterfly-side-view-100.png';
import clownFish from './icons8-clown-fish-100.png';
// ------------------------------------- A
import crab from './icons8-crab-100.png';
import dove from './icons8-dove-100.png';
import duck from './icons8-duck-100.png';
import elephant from './icons8-elephant-100.png';
import flamingo from './icons8-flamingo-100.png';
import frog from './icons8-frog-100.png';
import germanShepherd from './icons8-german-shepherd-100.png';
import giraffeFullBody from './icons8-giraffe-full-body-100.png';
// ------------------------------------- B
import koala from './icons8-koala-100.png';
import ladybird from './icons8-ladybird-100.png';
import leopard from './icons8-leopard-100.png';
import lobster from './icons8-lobster-100.png';
import mouseAnimal from './icons8-mouse-animal-100.png';
import parrot from './icons8-parrot-100.png';
import peacock from './icons8-peacock-100.png';
import penguin from './icons8-penguin-100.png';
// ------------------------------------- C
import sheep from './icons8-sheep-100.png';
import sloth from './icons8-sloth-100.png';
import snail from './icons8-snail-100.png';
import starfish from './icons8-starfish-100.png';
import stingray from './icons8-stingray-100.png';
import tiger from './icons8-tiger-100.png';
import turtle from './icons8-turtle-100.png';
import whale from './icons8-whale-100.png';
// ------------------------------------- D

const inventory = [
    {
        id: 1,
        image: alligator,
    },
    {
        id: 2,
        image: badger,
    },
    {
        id: 3,
        image: bear,
    },
    {
        id: 4,
        image: bee,
    },
    {
        id: 5,
        image: blackCat,
    },
    {
        id: 6,
        image: bull,
    },
    {
        id: 7,
        image: butterflySideView,
    },
    {
        id: 8,
        image: clownFish,
    },
    // ------------------------------------- A
    {
        id: 9,
        image: crab,
    },
    {
        id: 10,
        image: dove,
    },
    {
        id: 11,
        image: duck,
    },
    {
        id: 12,
        image: elephant,
    },
    {
        id: 13,
        image: flamingo,
    },
    {
        id: 14,
        image: frog,
    },
    {
        id: 15,
        image: germanShepherd,
    },
    {
        id: 16,
        image: giraffeFullBody,
    },
    // ------------------------------------- B
    {
        id: 17,
        image: koala,
    },
    {
        id: 18,
        image: ladybird,
    },
    {
        id: 19,
        image: leopard,
    },
    {
        id: 20,
        image: lobster,
    },
    {
        id: 21,
        image: mouseAnimal,
    },
    {
        id: 22,
        image: parrot,
    },
    {
        id: 23,
        image: peacock,
    },
    {
        id: 24,
        image: penguin,
    },
    // ------------------------------------- C
    {
        id: 25,
        image: sheep,
    },
    {
        id: 26,
        image: sloth,
    },
    {
        id: 27,
        image: snail,
    },
    {
        id: 28,
        image: starfish,
    },
    {
        id: 29,
        image: stingray,
    },
    {
        id: 30,
        image: tiger,
    },
    {
        id: 31,
        image: turtle,
    },
    {
        id: 32,
        image: whale,
    },
    // ------------------------------------- D
];

export default inventory;
