/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
    Form,
    Input,
    Button,
} from 'antd';

import './forgottenPassword.scss';
import '../../styles/style.scss';
import Authentication from '../../components/layouts/Authentication';
import useUser from '../../core/user/useUser';

const ForgottenPassword = () => {
    const {
        forgotPasswordRequest,
        user,
    } = useUser();

    const [form] = Form.useForm();

    const onFinish = (values) => {
        forgotPasswordRequest({
            email: values.email_address,
        });
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    return (
        <Authentication>
            <div
                className="ui container forgotten-password-wrapper"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                        width: '90%',
                        maxWidth: 550,
                    }}
                    layout="vertical">
                    <p
                        className="authentication-title">Forgotten Password
                    </p>
                    <p className="authentication-subtitle">Enter your email address below to reset your password.
                    </p>
                    <div className="horizontal-line" />
                    <Form.Item
                        className="authentication-form-item"
                        style={{
                            marginBottom: '50px',
                        }}
                        label="Email Address"
                        name="email_address"
                        rules={[{
                            required: true,
                            message: 'Please enter your email address',
                        }]}>
                        <Input placeholder="Enter email address" />
                    </Form.Item>
                    <Button
                        loading={user?.isFetching}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="authentication-submit">
                        Reset Password
                    </Button>
                </Form>
            </div>
        </Authentication>
    );
};

export default ForgottenPassword;
