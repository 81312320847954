/* eslint-disable quote-props */
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth } from '../utils/api';

import {
    getPatientsSuccess,
    getPatientsFailure,

    resetPatientPasswordSuccess,
    resetPatientPasswordFailure,
} from './patientActions';

const {
    GET_PATIENTS_REQUEST,
    RESET_PATIENT_PASSWORD_REQUEST,
} = require('./patientActions').constants;

function* getPatients({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'user/patients-list',
            params: payload,
        });

        yield put(getPatientsSuccess(response));
    } catch (e) {
        yield put(getPatientsFailure(e.response ? e.response.data.message : e));
    }
}

function* resetPatientPassword({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `user/reset-patient-password?patient_id=${payload?.userId}`,
        });

        yield put(resetPatientPasswordSuccess(response));
    } catch (e) {
        // alertify.error(e.response ? e.response.data.message : e);
        yield put(resetPatientPasswordFailure(e.response ? e.response.data.message : e));
    }
}

export default function* consultationsSaga() {
    yield* [
        takeEvery(GET_PATIENTS_REQUEST, getPatients),
        takeEvery(RESET_PATIENT_PASSWORD_REQUEST, resetPatientPassword),
    ];
}
