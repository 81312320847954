import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Select, Radio, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { downloadActionsRequest } from '../../../core/user/userActions';

import './exportActionForm.scss';

const { Option } = Select;

const ExportActionForm = ({ clinicId, userId }) => {
    const Tag = ({ tag, onClick = () => { } }) => (
        <button
            onClick={() => onClick(tag?.value)}
            type="button"
            style={{
                height: '46px',
                backgroundColor: '#F4F9FC',
                borderRadius: '50px',
                minWidth: '200px',
                maxWidth: '400px',
                fontSize: '18px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'hidden',
                margin: 5,
                border: 'none',
            }}>
            <text
                style={{
                    margin: 10,
                    color: '#4B4A4A',
                    font: 'Open Sans',
                    fontSize: 16,
                }}>
                Exporting Patient ID: {tag?.label}
            </text>
            <CloseOutlined />
        </button>
    );

    Tag.defaultProps = {
        onClick: () => { },
        tag: {},
    };

    Tag.propTypes = {
        onClick: PropTypes.func,
        tag: PropTypes.object,
    };

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [exportType, setExportType] = useState(1);
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const { allPatients } = useSelector((state) => state.patient);

    const tags = allPatients.map((patient) => ({
        ...patient,
        value: patient?.id,
        label: patient?.email,
    })).filter(val => val?.label?.includes(searchTerm)).slice(0, 5);

    const exportTypes = {
        all: 1,
        individual: 2,
    };

    function handleTagClose(tagId) {
        setSelectedTags(selectedTags.filter((tag) => tag?.value !== tagId));
    }

    function handleSetExportType(value) {
        setExportType(value);
        setSelectedTags([]);
        setSearchTerm('');
    }

    function handleOptionSelect(value, option) {
        const T = tags.find((t) => t?.value === option?.value);
        const selectedT = selectedTags.find((t) => t?.value === option?.value);
        if (!selectedT) {
            setSelectedTags([T]);
            setSearchTerm('');
        }
    }

    function handleExport() {
        let clinic = clinicId;
        if (!clinic && userId) {
            const { clinic_id } = allPatients?.find(p => p?.id === userId);
            clinic = clinic_id;
        }
        dispatch(downloadActionsRequest({ patients: selectedTags?.map(val => +val?.id), clinicId: clinic }));
    }

    function handleSetSearchTerm(value) {
        setSearchTerm(value);
    }

    return (
        <Form
            form={form}
            className="export-action-form"
            layout="vertical">
            <h1 className="page-title">Export</h1>
            <div className="horizontal-line" />
            <Space
                direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <p>You can export the clinics Actions List for the last 30 days as a CSV file. Please choose if you would to export all actions, or only the actions relating to one patient:</p>
                <Form.Item>
                    <Radio.Group
                        onChange={(e) => handleSetExportType(e?.target?.value)}
                        value={exportType}
                        size="large"
                        buttonStyle="solid">
                        <Space
                            direction="vertical">
                            <Radio value={exportTypes?.all}>Export All</Radio>
                            <Radio value={exportTypes?.individual}>Export Patient</Radio>
                        </Space>
                    </Radio.Group>
                    <Form.Item>
                        <Select
                            id="patient-auto-complete-A"
                            placeholder="Search"
                            showSearch
                            allowClear
                            disabled={exportType === exportTypes?.all}
                            autoClearSearchValue
                            size="large"
                            style={{
                                width: '50%',
                                minWidth: 300,
                                fontSize: 18,
                                color: 'F4F9FC',
                                margin: '30px 0px 0px 0px',
                            }}
                            onSearch={(value) => handleSetSearchTerm(value)}
                            onSelect={(value, option) => handleOptionSelect(value, option)}
                            value={searchTerm}
                            filterOption={false}
                            suffixIcon={null}>
                            {tags.map((tag) => <Option value={tag?.value}>{tag?.label}</Option>)}
                        </Select>
                    </Form.Item>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                    }}>
                        {selectedTags.map((tag) => (
                            <Tag
                                tag={tag}
                                onClick={handleTagClose} />
                        ))}
                    </div>
                </Form.Item>
            </Space>
            <Form.Item style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Button
                    className="large-button"
                    style={{
                        width: 550,
                        maxWidth: '100%',
                        border: 'none',
                    }}
                    size="large"
                    disabled={exportType === 2 && selectedTags?.length === 0}
                    type="primary"
                    onClick={handleExport}>
                    Export
                </Button>
            </Form.Item>
        </Form>
    );
};

ExportActionForm.defaultProps = {
    clinicId: null,
    userId: null,
};

ExportActionForm.propTypes = {
    clinicId: PropTypes.number,
    userId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default ExportActionForm;
