import React, { useEffect } from 'react';

// import { Link } from 'react-router-dom';
import { Table, Button, Tooltip } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import history from '../../core/utils/history';
import Standard from '../../components/layouts/Standard';
import SearchForm from './SearchForm';
import SummaryBlock from '../../components/SummaryBlock';

import { getClinicsRequest, getGeneralSummaryRequest, setSingleClinicRequest } from '../../core/clinic/clinicActions';

import './clinics.scss';
import { InfoOutlined } from '../../components/icons';

const Clinics = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClinicsRequest());
        dispatch(getGeneralSummaryRequest());
    }, []);

    const { displayClinics, isFetching, generalSummary } = useSelector((state) => state.clinic);

    const getTherapyTypeText = (therapy_type) => {
        switch (therapy_type) {
        case 1:
            return 'Physical';
        case 2:
            return 'Occupational';
        case 3:
            return 'Physical & Occupational';
        default:
            return 'N/a';
        }
    };

    const openClinic = (clinicId) => {
        dispatch(setSingleClinicRequest(clinicId));
        history.push('single-clinic');
    };

    const renderTooltip = () => (
        <Tooltip title="Clicking the arrows will filter the results by highest to lowest - or vice versa - number of patients in each status category, to help you better understand how each clinic is performing." color="#3F8CC8">
            <div className="info-button"><InfoOutlined /></div>
        </Tooltip>
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'therapy_type',
            sorter: {
                compare: (a, b) => a.therapy_type - b.therapy_type,
                multiple: 3,
            },
            render: (_, record) => (
                <span className="signal-wrapper">
                    {getTherapyTypeText(record?.therapy_type)}
                </span>
            ),
        },
        {
            title: 'Completed',
            dataIndex: 'numberPatientsCompletedTherapy',
            sorter: {
                compare: (a, b) => a.numberPatientsCompletedTherapy - b.numberPatientsCompletedTherapy,
                multiple: 2,
            },
            render: (_, record) => (
                <span className="signal-wrapper">
                    <div className="signal-indicator" /> {record.numberPatientsCompletedTherapy}
                </span>
            ),
        },
        {
            title: 'In Progress',
            dataIndex: 'numberPatientsInTherapy',
            sorter: {
                compare: (a, b) => a.numberPatientsInTherapy - b.numberPatientsInTherapy,
                multiple: 1,
            },
            render: (_, record) => (
                <span className="signal-wrapper">
                    <div className="signal-indicator warning" /> {record.numberPatientsInTherapy}
                </span>
            ),
        },
        {
            title: <div>Action Required</div>,
            dataIndex: 'numberPatientActions',
            sorter: {
                compare: (a, b) => a.numberPatientsWithActions - b.numberPatientsWithActions,
                multiple: 1,
            },
            render: (_, record) => (
                <span className="signal-wrapper">
                    <div className="signal-indicator danger" /> {record.numberPatientsWithActions}
                </span>
            ),
        },
        {
            title: renderTooltip(),
            key: 'action',
            render: (_, record) => (
                <Button
                    className="view-button"
                    size="large"
                    type="primary"
                    onClick={() => openClinic(record.id)}>View
                </Button>
            ),
        },
    ];

    return (
        <Standard title="Clinics">
            <div>
                <h1 className="page-title">Clinics</h1>
                <div className="horizontal-line" />

                <SummaryBlock
                    successBlockNumber={generalSummary?.numberPatientsCompletedTherapy}
                    warningBlockNumber={generalSummary?.numberPatientsInTherapy}
                    dangerBlockNumber={generalSummary?.numberPatientsRequiringAction} />

                <div className="search-block">
                    <div className="form-content">
                        <SearchForm />
                    </div>
                    <div className="table-summary-block">
                        <div className="summary-item">
                            <div className="left-side">
                                <div className="circle-icon" /> Completed:
                            </div>
                            <div className="right-side">
                                Number of patients who have completed their therapy
                            </div>
                        </div>
                        <div className="summary-item">
                            <div className="left-side">
                                <div className="circle-icon warning" /> In Progress:
                            </div>
                            <div className="right-side">
                                Number of patients currently in therapy
                            </div>
                        </div>
                        <div className="summary-item">
                            <div className="left-side">
                                <div className="circle-icon danger" /> Action Required:
                            </div>
                            <div className="right-side">
                                Number of patients requiring action
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-block stripped-table">
                    <Table
                        rowKey="id"
                        loading={isFetching}
                        columns={columns}
                        pagination={false}
                        dataSource={displayClinics}
                        onChange={(something) => console.log('on change', something)} />
                </div>
            </div>
        </Standard>
    );
};

export default Clinics;

Clinics.propTypes = {};
