import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_CLINICS_REQUEST: null,
    GET_CLINICS_SUCCESS: null,
    GET_CLINICS_FAILURE: null,

    FILTER_CLINICS_REQUEST: null,

    SORT_CLINICS_REQUEST: null,

    GET_GENERAL_SUMMARY_REQUEST: null,
    GET_GENERAL_SUMMARY_SUCCESS: null,
    GET_GENERAL_SUMMARY_FAILURE: null,

    SET_SINGLE_CLINIC_REQUEST: null,

    GET_SINGLE_CLINIC_FROM_SERVER_REQUEST: null,
    GET_SINGLE_CLINIC_FROM_SERVER_SUCCESS: null,
    GET_SINGLE_CLINIC_FROM_SERVER_FAILURE: null,

    GET_CLINIC_BY_ID_REQUEST: null,
    GET_CLINIC_BY_ID_SUCCESS: null,
    GET_CLINIC_BY_ID_FAILURE: null,
});

export const {
    getClinicsRequest,
    getClinicsSuccess,
    getClinicsFailure,
} = createActions(
    constants.GET_CLINICS_REQUEST,
    constants.GET_CLINICS_SUCCESS,
    constants.GET_CLINICS_FAILURE,
);

export const {
    filterClinicsRequest,
} = createActions(
    constants.FILTER_CLINICS_REQUEST,
);

export const {
    sortClinicsRequest,
} = createActions(
    constants.SORT_CLINICS_REQUEST,
);

export const {
    getGeneralSummaryRequest,
    getGeneralSummarySuccess,
    getGeneralSummaryFailure,
} = createActions(
    constants.GET_GENERAL_SUMMARY_REQUEST,
    constants.GET_GENERAL_SUMMARY_SUCCESS,
    constants.GET_GENERAL_SUMMARY_FAILURE,
);

export const {
    setSingleClinicRequest,
} = createActions(
    constants.SET_SINGLE_CLINIC_REQUEST,
);

export const {
    getSingleClinicFromServerRequest,
    getSingleClinicFromServerSuccess,
    getSingleClinicFromServerFailure,
} = createActions(
    constants.GET_SINGLE_CLINIC_FROM_SERVER_REQUEST,
    constants.GET_SINGLE_CLINIC_FROM_SERVER_SUCCESS,
    constants.GET_SINGLE_CLINIC_FROM_SERVER_FAILURE,
);

export const {
    getClinicByIdRequest,
    getClinicByIdSuccess,
    getClinicByIdFailure,
} = createActions(
    constants.GET_CLINIC_BY_ID_REQUEST,
    constants.GET_CLINIC_BY_ID_SUCCESS,
    constants.GET_CLINIC_BY_ID_FAILURE,
);
