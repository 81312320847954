import React, { useEffect } from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { isEmpty } from 'underscore';

// import { InfoOutlined } from '@ant-design/icons';
import {
    Table,
    Button,
    // Tooltip,
    notification,
    Empty,
    Spin,
} from 'antd';

import SearchForm from './SearchForm';

import history from '../../core/utils/history';
import {
    resetPatientPasswordRequest,
    setSinglePatientRequest,
} from '../../core/patient/patientActions';

import iconInventory from '../../assets/images/profileIcons/iconInventory';

import './patients.scss';

let count = 0;

const PatientsBlock = () => {
    const dispatch = useDispatch();

    const isFetching = useSelector((state) => state.patient.isFetching);
    const displayPatients = useSelector((state) => state.patient.displayPatients);
    const temporaryPassword = useSelector((state) => state.patient.temporaryPassword);

    useEffect(() => {
        count = 0;
    }, []);

    useEffect(() => {
        if (temporaryPassword && count > 0) {
            notification.success({
                message: `Password creation successful - the patients new password is "${temporaryPassword}"`,
            });
        }
        count += 1;
    }, [temporaryPassword]);

    // const renderViewTooltip = () => (
    //     <Tooltip title="Clicking the arrows will filter the results by highest to lowest - or vice versa." color="#3F8CC8">
    //         <div className="info-button"><InfoOutlined /></div>
    //     </Tooltip>
    // );

    // const renderPasawordResetTooltip = () => (
    //     <Tooltip title="Clicking the create password button will present a newly generated password for the selected patient." color="#3F8CC8">
    //         <div className="info-button"><InfoOutlined /></div>
    //     </Tooltip>
    // );

    // const statuses = {
    //     0: 'Not active',
    //     10: 'Active',
    //     20: 'Archived',
    // };

    const journeyStages = {
        1: 'Evaluation',
        2: 'Setup',
        3: 'Telehealth visit',
        4: 'Re-evaluation',
    };

    const getStatusColor = (patient) => {
        if (patient?.urgentActionRequired) {
            return 'danger';
        } if (patient?.actionRequired) {
            return 'warning';
        }
        return 'success';
    };

    // const getStatusValue = (patient) => {
    //     if (patient?.urgentActionRequired) {
    //         return 3;
    //     } if (patient?.actionRequired) {
    //         return 2;
    //     }
    //     return 1;

    // };

    const openPatient = (patientId) => {
        dispatch(setSinglePatientRequest(patientId));
        history.push('single-patient');
    };

    const columns = [
        {
            // title: 'Icon',
            dataIndex: 'id',
            render: (_, record) => {
                const image = iconInventory.find((val) => val?.id === record?.profile_icon_id);
                return (
                    image?.image ? <img style={{ height: 40, aspectRatio: 1, borderRadius: 20, border: '0.5px solid #DADADA' }} src={image?.image} alt="Profile Icon" /> : null
                );
            },
        },
        {
            title: 'Patient ID',
            dataIndex: 'patient_id',
            // sorter: {
            //     compare: (a, b) => a.patient_id > b.patient_id,
            //     multiple: 3,
            // },
            render: (_, record) => (
                <span className="signal-wrapper">
                    {record.patient_id}
                </span>
            ),
        },
        {
            title: 'Stage in Journey',
            dataIndex: 'journey_stage',
            // sorter: {
            //     compare: (a, b) => b.journey_stage - a.journey_stage,
            //     multiple: 1,
            // },
            render: (_, record) => (
                <span className="signal-wrapper">
                    {journeyStages[record.journey_stage]}
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            // sorter: {
            //     compare: (a, b) => getStatusValue(a) - getStatusValue(b),
            //     multiple: 2,
            // },
            render: (_, record) => (
                <span className="signal-wrapper">
                    <div className={`signal-indicator ${getStatusColor(record)}`} />
                </span>
            ),
        },
        {
            // title: renderViewTooltip(),
            key: 'action',
            render: (_, record) => (
                <Button
                    className="view-button"
                    size="large"
                    type="primary"
                    onClick={() => openPatient(record.id)}>
                    View
                </Button>
            ),
        },
        {
            // title: renderPasawordResetTooltip(),
            key: 'action',
            render: (_, record) => (
                <Button
                    className="view-button"
                    size="large"
                    type="secondary"
                    onClick={() => dispatch(resetPatientPasswordRequest({ userId: record.id }))}>
                    Create Password
                </Button>
            ),
        },
    ];

    return (
        <Spin tip="Loading" size="small" spinning={isFetching}>
            <div>
                <div className="search-block">
                    <div className="form-content">
                        <SearchForm />
                    </div>
                    <div className="table-summary-block">
                        <div className="left-side">
                            <div className="circle-icon" /> No Action Required
                        </div>
                        <div className="left-side">
                            <div className="circle-icon warning" /> Customer Journey Action Required
                        </div>
                        <div className="left-side">
                            <div className="circle-icon danger" /> Urgent Action Required
                        </div>
                    </div>
                </div>
                <div className="content-block stripped-table">
                    {isEmpty(displayPatients)
                        ? <Empty description="No Results Found" />
                        : (
                            <Table
                                rowKey="id"
                                loading={isFetching}
                                columns={columns}
                                pagination={false}
                                dataSource={displayPatients}
                                onChange={(something) => console.log('on change', something)} />
                        )}
                </div>
            </div>
        </Spin>
    );
};

export default PatientsBlock;

PatientsBlock.propTypes = {};
