import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    GET_PATIENTS_REQUEST: null,
    GET_PATIENTS_SUCCESS: null,
    GET_PATIENTS_FAILURE: null,

    SORT_PATIENTS_REQUEST: null,

    FILTER_PATIENTS_REQUEST: null,

    RESET_PATIENT_PASSWORD_REQUEST: null,
    RESET_PATIENT_PASSWORD_SUCCESS: null,
    RESET_PATIENT_PASSWORD_FAILURE: null,

    SET_SINGLE_PATIENT_REQUEST: null,
    SET_SINGLE_PATIENT_SUCCESS: null,
    SET_SINGLE_PATIENT_FAILURE: null,

    UPDATE_PATIENT_REQUEST_REQUEST: null,
});

export const {
    getPatientsRequest,
    getPatientsSuccess,
    getPatientsFailure,

    sortPatientsRequest,

    filterPatientsRequest,

    resetPatientPasswordRequest,
    resetPatientPasswordSuccess,
    resetPatientPasswordFailure,

    setSinglePatientRequest,
    setSinglePatientSuccess,
    setSinglePatientFailure,

    updatePatientRequestRequest,
} = createActions(
    constants.GET_PATIENTS_REQUEST,
    constants.GET_PATIENTS_SUCCESS,
    constants.GET_PATIENTS_FAILURE,

    constants.SORT_PATIENTS_REQUEST,

    constants.FILTER_PATIENTS_REQUEST,

    constants.RESET_PATIENT_PASSWORD_REQUEST,
    constants.RESET_PATIENT_PASSWORD_SUCCESS,
    constants.RESET_PATIENT_PASSWORD_FAILURE,

    constants.SET_SINGLE_PATIENT_REQUEST,
    constants.SET_SINGLE_PATIENT_SUCCESS,
    constants.SET_SINGLE_PATIENT_FAILURE,

    constants.UPDATE_PATIENT_REQUEST_REQUEST,
);
