import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import './tag.scss';

const Tag = ({ title, onClose, remove, onClick, style }) => (
    <div
        className={`tag-item${remove ? '' : ' clean'}`}
        onClick={onClick}
        style={style}>
        {title}
        {remove ? (
            <Button type="text" onClick={onClose}>
                <CloseOutlined />
            </Button>
        )
            : null}
    </div>
);

Tag.defaultProps = {
    title: '',
    onClose: () => {},
    remove: true,
    onClick: () => {},
    style: {},
};

Tag.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    remove: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

export default Tag;
