import { Record } from 'immutable';
import { filter, isEmpty, sortBy, findIndex } from 'underscore';

import { SORT_BY_NEWEST, SORT_BY_OLDEST, SORT_BY_ALPHABETIC } from '../utils/helpers';

const {
    GET_CLINICS_REQUEST,
    GET_CLINICS_SUCCESS,
    GET_CLINICS_FAILURE,

    FILTER_CLINICS_REQUEST,

    SORT_CLINICS_REQUEST,

    GET_GENERAL_SUMMARY_REQUEST,
    GET_GENERAL_SUMMARY_SUCCESS,
    GET_GENERAL_SUMMARY_FAILURE,

    SET_SINGLE_CLINIC_REQUEST,

    GET_SINGLE_CLINIC_FROM_SERVER_REQUEST,
    GET_SINGLE_CLINIC_FROM_SERVER_SUCCESS,
    GET_SINGLE_CLINIC_FROM_SERVER_FAILURE,

    GET_CLINIC_BY_ID_REQUEST,
    GET_CLINIC_BY_ID_SUCCESS,
    GET_CLINIC_BY_ID_FAILURE,

} = require('./clinicActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
    allClinics: [],
    displayClinics: [],
    singleClinic: {},
    generalSummary: {},
});

const initialState = new InitialState();

export default function consultationReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case GET_CLINICS_REQUEST:
    case GET_GENERAL_SUMMARY_REQUEST:
    case GET_SINGLE_CLINIC_FROM_SERVER_REQUEST:
    case GET_CLINIC_BY_ID_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_CLINICS_SUCCESS: {
        return state.set('isFetching', false)
            .set('allClinics', payload.data)
            .set('displayClinics', payload.data);
    }

    case FILTER_CLINICS_REQUEST: {
        const { allClinics } = state;

        let displayClinics = allClinics;
        if (!isEmpty(payload)) {
            displayClinics = filter(allClinics, (str) => { if (str.name.toLowerCase().indexOf(payload.toLowerCase()) !== -1) return str; return ''; });
        }

        return state.set('displayClinics', displayClinics);
    }

    case SORT_CLINICS_REQUEST: {
        const { allClinics } = state;

        let displayClinics = allClinics;
        if (payload !== '') {
            if (payload === SORT_BY_NEWEST) {
                displayClinics = sortBy(allClinics, 'created_at').reverse();
            }

            if (payload === SORT_BY_OLDEST) {
                displayClinics = sortBy(allClinics, 'created_at');
            }

            if (payload === SORT_BY_ALPHABETIC) {
                displayClinics = sortBy(allClinics, 'name');
            }
        }

        return state.set('displayClinics', displayClinics);
    }

    case GET_GENERAL_SUMMARY_SUCCESS: {
        return state.set('isFetching', false)
            .set('generalSummary', payload.data);
    }

    case SET_SINGLE_CLINIC_REQUEST: {
        const { allClinics } = state;
        let { singleClinic } = state;

        const clinicIndex = findIndex(allClinics, (val) => val.id === payload);
        if (clinicIndex !== -1) {
            singleClinic = allClinics[clinicIndex];
        }

        return state.set('singleClinic', singleClinic);
    }

    case GET_CLINIC_BY_ID_SUCCESS: {
        const { data } = payload;
        return state.set('singleClinic', data);
    }

    case GET_SINGLE_CLINIC_FROM_SERVER_SUCCESS:
        return state.set('isFetching', false)
            .set('singleClinic', payload.data);

    case GET_CLINICS_FAILURE:
    case GET_GENERAL_SUMMARY_FAILURE:
    case GET_SINGLE_CLINIC_FROM_SERVER_FAILURE:
    case GET_CLINIC_BY_ID_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}
