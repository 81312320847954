import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { Form, Input, Col, Row, Checkbox, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Tag from '../Tag';

import { filterAlertsRequest, setFilterOptionsRequest, setNameTagRequest, setPatientIdTagRequest } from '../../../core/alert/alertActions';

import './alertsSearchForm.scss';

const SearchForm = ({ addAction, exportAction }) => {
    const dispatch = useDispatch();
    const [searchKey, setSearchKey] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(filterAlertsRequest(searchKey));
            // Send Axios request here
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchKey]);

    useEffect(() => {
        setSearchKey('');
    }, []);

    const { nameTag, patientIdTag, onlyAlerts, onlyActions } = useSelector((state) => state.alert);

    const onChange = (e, actionType) => {
        dispatch(setFilterOptionsRequest({ option: actionType, value: e.target.checked }));
    };

    return (
        <Form
            className="alerts-and-actions-search-form"
            onFinish={() => console.log('on finish')}
            onFinishFailed={() => console.log('on finish failed')}
            layout="vertical">

            <Row>
                <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                    <Form.Item
                        className="icon-item"
                        name="search_key">
                        <Input prefix={<SearchOutlined className="site-form-item-icon" />} placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} />
                    </Form.Item>
                </Col>
                <Col xs={20} sm={16} md={12} lg={8} xl={4} className="middle-col">
                    <p>Show results for:</p>
                    <Checkbox checked={onlyAlerts} onChange={(e) => onChange(e, 'onlyAlerts')}>Alerts</Checkbox>
                    <Checkbox checked={onlyActions} onChange={(e) => onChange(e, 'onlyActions')}>Actions</Checkbox>
                </Col>
                <Col xs={2} sm={4} md={6} lg={8} xl={10} className="last-col">
                    <Button
                        className="medium-button"
                        size="large"
                        type="primary"
                        onClick={() => addAction()}>Add Action
                    </Button>
                    <Button
                        className="medium-button"
                        size="large"
                        type="primary"
                        onClick={() => exportAction()}>Export Action
                    </Button>
                </Col>
            </Row>
            <div className="tag-row">
                {!isEmpty(nameTag) ? <Tag title={`Assigned to: ${nameTag}`} onClose={() => dispatch(setNameTagRequest(''))} /> : null}
                {!isEmpty(patientIdTag) ? <Tag title={`Patient ID: ${patientIdTag}`} onClose={() => dispatch(setPatientIdTagRequest(''))} /> : null}
            </div>

        </Form>
    );
};

SearchForm.defaultProps = {
    addAction: () => {},
    exportAction: () => {},
};

SearchForm.propTypes = {
    addAction: PropTypes.func,
    exportAction: PropTypes.func,
};

export default SearchForm;
