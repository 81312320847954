/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Main from '../Main';
import './authentication.scss';
import authenticationScreen from '../../../assets/images/authentication-screen.png';
import sensTrain from '../../../assets/images/sens-train.png';

const Authentication = ({ children }) => {
    return (
        <Main>
            <div
                className="container authentication-wrapper"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                <div style={{
                    backgroundImage: `url(${authenticationScreen})`,
                    width: '50%',
                    height: '100vh',
                    backgroundSize: 'contain',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <img
                        style={{
                            width: '66%',
                        }}
                        alt="Sens Train"
                        src={sensTrain} />
                </div>
                <div style={{
                    backgroundColor: 'white',
                    width: '50%',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {children}
                </div>
            </div>
        </Main>
    );
};

Authentication.propTypes = {
    children: PropTypes.node,
};

Authentication.defaultProps = {
    children: null,
};

export default Authentication;
