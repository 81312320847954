/* eslint-disable quote-props */
import { put, call, takeEvery } from 'redux-saga/effects';
// import { notification } from 'antd';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getAlertsSuccess,
    getAlertsFailure,

    createActionSuccess,
    createActionFailure,

    convertToActionSuccess,
    convertToActionFailure,
} from './alertActions';
import { getClinicByIdRequest } from '../clinic/clinicActions';

const {
    GET_ALERTS_REQUEST,
    CREATE_ACTION_REQUEST,
    CONVERT_TO_ACTION_REQUEST,
} = require('./alertActions').constants;

function* getAlerts({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/communication',
            params: payload,
        });
        yield put(getAlertsSuccess(response));
    } catch (e) {
        // message.error(e.response ? e.response.data.message : e);
        yield put(getAlertsFailure(e.response ? e.response.data.message : e));
    }
}

function* createAction({ payload }) {
    // const [api] = notification.useNotification();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'communication/create-action',
            body: payload,
        });

        let message = 'Succesfully created action';
        if (payload.id) {
            message = 'Succesfully updated action';
        }

        notification.success({
            message: 'Success',
            description: message,
        });

        yield put(createActionSuccess(response));
        yield put(getClinicByIdRequest({ id: payload?.clinic_id }));
    } catch (e) {
        notification.error({
            message: 'Error',
            description: e.response ? e.response.data.message : 'Unable to create action',
        });
        yield put(createActionFailure(e.response ? e.response.data.message : e));
    }
}

function* convertToAction({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'communication/convert-to-action',
            body: payload,
        });

        notification.success({
            message: 'Success',
            description: 'Succesfully converted to action',
        });

        yield put(convertToActionSuccess(response));
    } catch (e) {
        notification.error({
            message: 'Error',
            description: e.response ? e.response.data.message : 'Unable to convert to action',
        });
        yield put(convertToActionFailure(e.response ? e.response.data.message : e));
    }
}

export default function* consultationsSaga() {
    yield* [
        takeEvery(GET_ALERTS_REQUEST, getAlerts),
        takeEvery(CREATE_ACTION_REQUEST, createAction),
        takeEvery(CONVERT_TO_ACTION_REQUEST, convertToAction),
    ];
}
