/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    Input,
    Button,
    notification,
} from 'antd';

import './createPassword.scss';
import Authentication from '../../components/layouts/Authentication';
import tickPoint from '../../assets/images/tick-point.png';
import tickPointGreen from '../../assets/images/tick-point-green.png';
import useUser from '../../core/user/useUser';

const CreatePassword = ({ match }) => {
    const {
        createPasswordRequest,
        user,
    } = useUser();

    const [form] = Form.useForm();

    const [firstFieldEmpty, setFirstFieldEmpty] = useState(false);
    const [secondFieldEmpty, setSecondFieldEmpty] = useState(false);
    const [passwordLengthPass, setPasswordLengthPass] = useState(false);
    const [passwordLetterNumberPass, setPasswordLetterNumberPass] = useState(false);
    const [passwordContainsUppercase, setPasswordContainsUppercase] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);

    const onFinish = (values) => {
        const { password, password_confirmation } = values;

        // Define rules
        setPasswordLengthPass(password && password.length > 7);
        const regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
        setPasswordLetterNumberPass(regExp.test(password));
        const regExpUppercase = /(?=.*[A-Z])/;
        setPasswordContainsUppercase(regExpUppercase.test(password));
        setPasswordMatch(password === password_confirmation);

        // Check rules
        let error = false;

        if (!password || !password_confirmation) {
            notification.error({
                message: 'Error',
                description:
                  'Please fill in all fields in the form to create an account',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            return;
        }

        if (!passwordLengthPass || !passwordLetterNumberPass || !passwordContainsUppercase) {
            notification.error({
                message: 'Error',
                description:
                  'Please enter valid password',
                onClick: () => {
                    console.log('Notification Clicked');
                },
            });
            error = true;
        }

        if (!passwordMatch) {
            notification.error({
                message: 'Error',
                description:
                  'The passwords do not match',
                onClick: () => {
                    console.log('Notification Clicked');
                },
            });
            error = true;
        }

        if (error) {
            return;
        }

        createPasswordRequest({
            email: match.params.email,
            password,
            passwordConfirmation: password_confirmation,
            token: match.params.token,
        });
    };

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const onValuesChange = (changedValues, allValues) => {
        const { password, password_confirmation } = allValues;

        // Define rules
        setPasswordLengthPass(password && password?.length > 7);
        const regExp = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
        setPasswordLetterNumberPass(regExp.test(password));
        const regExpUppercase = /(?=.*[A-Z])/;
        setPasswordContainsUppercase(regExpUppercase.test(password));
        setPasswordMatch(password === password_confirmation);
    };

    return (
        <Authentication>
            <div
                className="ui container create-password-wrapper"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Form
                    onValuesChange={onValuesChange}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                        width: '90%',
                        maxWidth: 550,
                    }}
                    layout="vertical">
                    <p
                        className="authentication-title">Create Password
                    </p>
                    <p className="authentication-subtitle">To complete the creation of your account, please enter a password you wish to use to access to your account.
                    </p>
                    <div className="horizontal-line" />
                    <Form.Item
                        className="authentication-form-item authentication-form-password-input"
                        label="Password"
                        name="password"
                        help={(
                            <div style={{
                                backgroundColor: '#F4F9FC',
                                borderRadius: 10,
                                marginTop: 10,
                                marginBottom: 10,
                                padding: 20,
                            }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} style={{ padding: 3 }}>
                                                <p style={{
                                                    color: '#4B4A4A',
                                                    fontFamily: 'Montserrat',
                                                    fontWeight: 600,
                                                    fontSize: '20px',
                                                    lineHeight: '24.38px',
                                                }}>Password must:
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: 3 }}><img src={passwordLengthPass ? tickPointGreen : tickPoint} alt="Sens Train" style={{ marginRight: 10 }} /></td>
                                            <td style={{ width: '100%', padding: 3 }}>
                                                <p style={{
                                                    color: passwordLengthPass ? '#228855' : '#4B4A4A',
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 400,
                                                    fontSize: '20px',
                                                    lineHeight: '27px',
                                                }}>Be at least 8 characters long
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: 3 }}><img src={passwordLetterNumberPass ? tickPointGreen : tickPoint} alt="Sens Train" style={{ marginRight: 10 }} /></td>
                                            <td style={{ width: '100%', padding: 3 }}>
                                                <p style={{
                                                    color: (passwordLetterNumberPass) ? '#228855' : '#4B4A4A',
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 400,
                                                    fontSize: '20px',
                                                    lineHeight: '27px',
                                                }}>Be a mixture of letters and numbers
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: 3 }}><img src={passwordContainsUppercase ? tickPointGreen : tickPoint} alt="Sens Train" style={{ marginRight: 10 }} /></td>
                                            <td style={{ width: '100%', padding: 3 }}>
                                                <p style={{
                                                    color: passwordContainsUppercase ? '#228855' : '#4B4A4A',
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 400,
                                                    fontSize: '20px',
                                                    lineHeight: '27px',
                                                }}>Contain at least 1 upper case letter
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}>
                        <Input.Password
                            style={{

                                backgroundColor: firstFieldEmpty ? '#ff3333' : 'none',
                            }}
                            placeholder="Enter password" />
                    </Form.Item>
                    <Form.Item
                        className="authentication-form-item authentication-form-password-input"
                        label="Verify Password"
                        name="password_confirmation">
                        <Input.Password
                            style={{
                                marginBottom: '50px',
                                backgroundColor: secondFieldEmpty ? '#ff3333' : 'none',
                            }}
                            placeholder="Re-enter password" />
                    </Form.Item>
                    <Button
                        loading={user.isFetching}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="authentication-submit">
                        Create Account
                    </Button>
                </Form>
            </div>
        </Authentication>
    );
};
CreatePassword.propTypes = {
    match: PropTypes.object.isRequired,
};

export default CreatePassword;
