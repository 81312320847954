import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { SORT_BY_NEWEST, SORT_BY_OLDEST, SORT_BY_ALPHABETIC } from '../../../core/utils/helpers';
import { filterClinicsRequest, sortClinicsRequest } from '../../../core/clinic/clinicActions';

const LoginForm = () => {
    const dispatch = useDispatch();
    const [searchKey, setSearchKey] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(filterClinicsRequest(searchKey));
            // Send Axios request here
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchKey]);

    useEffect(() => {
        setSearchKey('');
    }, []);

    return (
        <Form
            onFinish={() => console.log('on finish')}
            onFinishFailed={() => console.log('on finish failed')}
            layout="vertical">
            <Form.Item
                className="authentication-form-item icon-item"
                name="search_key">
                <Input prefix={<SearchOutlined className="site-form-item-icon" />} placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} />
            </Form.Item>
            <Form.Item
                name="sort_by"
                className="custom-select">
                <Select style={{ float: 'left' }} placeholder="Sort by" onChange={(value) => { dispatch(sortClinicsRequest(value)); }}>
                    <Select.Option value={SORT_BY_NEWEST}>Newest to Oldest</Select.Option>
                    <Select.Option value={SORT_BY_OLDEST}>Oldest to Newest</Select.Option>
                    <Select.Option value={SORT_BY_ALPHABETIC}>Alphabetically (A-Z)</Select.Option>
                </Select>
            </Form.Item>
        </Form>
    );
};

export default LoginForm;
