import React from 'react';
import PropTypes from 'prop-types';

import arrowImage from '../../assets/images/big-white-arrow.png';
import arrowBlue from '../../assets/images/big-blue-arrow.png';
import arrowGreen from '../../assets/images/big-green-arrow.png';
// import arrowImageLast from '../../assets/images/big-white-arrow-last.png';

import './arrowStatusBlock.scss';

const ArrowStatusBlock = ({ firstArrowTitle, secondArrowTitle, thirdArrowTitle, fourthArrowTitle, firstArrowStatus, secondArrowStatus, thirdArrowStatus, fourthArrowStatus }) => {

    let firstArrowExtraClass = '';
    let secondArrowExtraClass = '';
    let thirdArrowExtraClass = '';
    let fourthArrowExtraClass = '';

    let firstArrowImage = arrowImage;
    let secondArrowImage = arrowImage;
    let thirdArrowImage = arrowImage;
    let fourthArrowImage = arrowImage;

    if (firstArrowStatus === 1) {
        firstArrowExtraClass = ' blue';
        firstArrowImage = arrowBlue;
    }

    if (firstArrowStatus === 2) {
        firstArrowExtraClass = ' green';
        firstArrowImage = arrowGreen;
    }

    if (secondArrowStatus === 1) {
        secondArrowExtraClass = ' blue';
        secondArrowImage = arrowBlue;
    }

    if (secondArrowStatus === 2) {
        secondArrowExtraClass = ' green';
        secondArrowImage = arrowGreen;
    }

    if (thirdArrowStatus === 1) {
        thirdArrowExtraClass = ' blue';
        thirdArrowImage = arrowBlue;
    }

    if (thirdArrowStatus === 2) {
        thirdArrowExtraClass = ' green';
        thirdArrowImage = arrowGreen;
    }

    if (fourthArrowStatus === 1) {
        fourthArrowExtraClass = ' blue';
        fourthArrowImage = arrowBlue;
    }

    if (fourthArrowStatus === 2) {
        fourthArrowExtraClass = ' green';
        fourthArrowImage = arrowGreen;
    }

    return (
        <div className="arrow-status-block">
            <div className={`arrow-item${firstArrowExtraClass}`}>
                {firstArrowTitle}
                <img src={firstArrowImage} className="arrow" alt="arrow" />
            </div>
            <div className={`arrow-item${secondArrowExtraClass}`}>
                {secondArrowTitle}
                <img src={secondArrowImage} className="arrow" alt="arrow" />
            </div>
            <div className={`arrow-item${thirdArrowExtraClass}`}>
                {thirdArrowTitle}
                <img src={thirdArrowImage} className="arrow" alt="arrow" />
            </div>
            <div className={`arrow-item${fourthArrowExtraClass}`}>
                {fourthArrowTitle}
                <img src={fourthArrowImage} className="arrow" alt="arrow" />
            </div>
        </div>
    );
};

ArrowStatusBlock.defaultProps = {
    firstArrowTitle: <>SensTrain<br />Evalution</>,
    secondArrowTitle: <>SensTrain<br />Set-Up</>,
    thirdArrowTitle: <>Telehealth<br />Visit</>,
    fourthArrowTitle: <>Re-Evaluation of PT<br />Plan of Care</>,
    firstArrowStatus: 0,
    secondArrowStatus: 0,
    thirdArrowStatus: 0,
    fourthArrowStatus: 0,
};

ArrowStatusBlock.propTypes = {
    firstArrowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    secondArrowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    thirdArrowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    fourthArrowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    firstArrowStatus: PropTypes.number,
    secondArrowStatus: PropTypes.number,
    thirdArrowStatus: PropTypes.number,
    fourthArrowStatus: PropTypes.number,
};

export default ArrowStatusBlock;
