import React, { useEffect } from 'react';
import {
    useSelector,
} from 'react-redux';
// eslint-disable-next-line
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';

import {
    notification,
    Spin,
} from 'antd';
// import moment from 'moment';

import './chartBlock.scss';

let count = 0;

const ChartBlock = () => {
    const isFetching = useSelector((state) => state.patient.isFetching);
    const temporaryPassword = useSelector((state) => state.patient.temporaryPassword);

    const secondsToMinutes = (seconds) => seconds / 60;

    useEffect(() => {
        count = 0;
    }, []);

    useEffect(() => {
        if (temporaryPassword && count > 0) {
            notification.success({
                message: `Password creation successful - the patients new password is "${temporaryPassword}"`,
            });
        }
        count += 1;
    }, [temporaryPassword]);

    function getRange(startDate, endDate, type) {
        const fromDate = moment(startDate);
        const toDate = moment(endDate);
        const diff = toDate.diff(fromDate, type);
        const range = [];
        for (let i = 0; i < diff; i++) {
            range.push(moment(startDate).add(i, type).format('YYYY-M-D'));
        }
        return range;
    }

    const singlePatient = useSelector((state) => state.patient.singlePatient);
    const assessmentMeta = singlePatient?.assessmentMeta;

    let range = getRange(moment().subtract(6, 'days'), moment().add(1, 'days'), 'days');

    range = range?.sort((a, b) => (moment(b, 'YYYY-MM-DD') - moment(a, 'YYYY-MM-DD')));

    let maxTime = 0;

    const metaMap = range.map((val) => {
        let singleMeta = {};
        if (assessmentMeta) {
            singleMeta = assessmentMeta[moment(val, 'YYYY-M-D').format('YYYY-MM-DD')] || [];
        }
        const day = moment(val, 'YYYY-M-D').format('dddd');
        const combined = {
            timeSpentAssessment: 0,
            timeSpentTraining: 0,
        };

        if (singleMeta) {
            Object.values(singleMeta).forEach(item => {
                combined.timeSpentAssessment += secondsToMinutes(item.timeSpentAssessment);
                combined.timeSpentTraining += secondsToMinutes(item.timeSpentTraining);
            });
        }
        const timeSpentAssessment = Math.min(Math.round(combined.timeSpentAssessment), 60);
        const timeSpentTraining = Math.min(Math.round(combined.timeSpentTraining), 60);
        if (timeSpentAssessment > maxTime) maxTime = timeSpentAssessment;
        if (timeSpentTraining > maxTime) maxTime = timeSpentTraining;
        return { ...singleMeta, day, Assessment: timeSpentAssessment || 0, Training: timeSpentTraining || 0 };
    });

    return (
        <Spin tip="Loading" size="small" spinning={isFetching}>
            <div className="chart-block-wrapper">
                <div className="content-block stripped-table">
                    <ResponsiveContainer width="100%" height={600}>
                        <BarChart
                            width={500}
                            height={300}
                            data={metaMap}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="day" />
                            <YAxis
                                tickCount={7}
                                interval={0}
                                domain={[0, 60]}
                                tickSize={10} />
                            <Tooltip />
                            <Bar
                                dataKey="Training"
                                fill="#a6c075"
                                radius={[30, 30, 0, 0]} />
                            <Bar
                                dataKey="Assessment"
                                fill="#3F8CC8"
                                radius={[30, 30, 0, 0]} />
                        </BarChart>
                    </ResponsiveContainer>

                    <p className="chart-label left-label">Minutes</p>
                    <p className="chart-label bottom-label">Day</p>

                    <div className="daily-progress-legend small">
                        <table>
                            <tbody>
                                <tr>
                                    <td><div className="circle-icon light-green" aria-label="Training Circle" /></td>
                                    <td><span className="label">Training</span></td>
                                    <td aria-label="Empty Cell" />
                                </tr>
                                <tr>
                                    <td><div className="circle-icon blue" aria-label="Assessment Circle" /></td>
                                    <td><span className="label">Assessment</span></td>
                                    <td aria-label="Empty Cell" />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ChartBlock;

ChartBlock.propTypes = {};
