import React, { useEffect, useState } from 'react';
import {
    useSelector,
} from 'react-redux';
import { isEmpty } from 'underscore';
import moment from 'moment';

import {
    Table,
    notification,
    Empty,
    Spin,
    Tooltip,
    Form,
    Checkbox,
} from 'antd';

// import SearchForm from './SearchForm';

import './scores.scss';
import { InfoOutlined } from '../icons';

let count = 0;

const ScoresBlock = () => {
    const isFetching = useSelector((state) => state.patient.isFetching);
    const temporaryPassword = useSelector((state) => state.patient.temporaryPassword);

    const [isTraining, setIsTraining] = useState(true);
    const [isAssessment, setIsAssessment] = useState(true);

    const singlePatient = useSelector((state) => state.patient.singlePatient);
    const assessmentRecords = singlePatient?.assessmentRecords?.sort((a, b) => moment(b?.completed_timestamp) - moment(a?.completed_timestamp))?.slice(0, 30)?.filter((val) => {
        const type = val?.type;
        if (!isTraining && type === 2) {
            return false;
        }
        if (!isAssessment && type === 1) {
            return false;
        }
        return true;
    }).map((val) => {
        const val2 = val;
        const completedTimestamp = moment(val?.completed_timestamp)?.format('YYYY-MM-DD');
        const singleMeta = singlePatient?.assessmentMeta[completedTimestamp];
        val2.score = (singleMeta?.find(meta => meta.date === val2?.completed_timestamp))?.score;
        return val2;
    });

    useEffect(() => {
        count = 0;
    }, []);

    useEffect(() => {
        if (temporaryPassword && count > 0) {
            notification.success({
                message: `Password creation successful - the patients new password is "${temporaryPassword}"`,
            });
        }
        count += 1;
    }, [temporaryPassword]);

    const getTestTypeText = (therapy_type) => {
        switch (therapy_type) {
        case 1:
            return 'Assessment';
        case 2:
            return 'Training';
        default:
            return 'N/a';
        }
    };

    const renderTooltip = () => (
        <Tooltip title="The Score metric is the highest score achieved by the patient on the date recorded." color="#3F8CC8">
            <div className="info-button"><InfoOutlined /></div>
        </Tooltip>
    );

    const columns = [
        {
            title: 'Test Type',
            dataIndex: 'type',
            render: (_, record) => (
                <span className="signal-wrapper">
                    {getTestTypeText(record.type)}
                </span>
            ),
        },
        {
            title: 'Level',
            dataIndex: 'level',
        },
        {
            title: <div className="tooltip-wrapper">Score {renderTooltip()}</div>,
            dataIndex: 'score',
            render: (_, record) => (
                `${Math.round(record.score)}%`
            ),
        },
        {
            title: 'Date',
            dataIndex: '',
            render: (_, record) => (
                <span className="signal-wrapper">
                    {moment(record?.completed_timestamp).format('D/M/YYYY')}
                </span>
            ),
        },
    ];

    return (
        <Spin tip="Loading" size="small" spinning={isFetching}>
            <div className="score-block-wrapper">
                <div className="search-block">
                    <div className="form-content">
                        <Form
                            className="scores-form"
                            onFinish={() => console.log('on finish')}
                            onFinishFailed={() => console.log('on finish failed')}
                            layout="vertical">

                            <p>Select which test type(s) you want to view:</p>
                            <Checkbox
                                checked={isTraining}
                                onChange={(e) => setIsTraining(e.target.checked)}>
                                Training
                            </Checkbox>
                            <Checkbox
                                checked={isAssessment}
                                onChange={(e) => setIsAssessment(e.target.checked)}>
                                Assessment
                            </Checkbox>
                        </Form>
                    </div>
                </div>
                <div className="content-block stripped-table">
                    {isEmpty(assessmentRecords)
                        ? <Empty description="No Results Found" />
                        : (
                            <Table
                                rowKey="id"
                                loading={isFetching}
                                columns={columns}
                                pagination={false}
                                dataSource={assessmentRecords}
                                onChange={(something) => console.log('on change', something)} />
                        )}
                </div>
            </div>
        </Spin>
    );
};

export default ScoresBlock;

ScoresBlock.propTypes = {};
