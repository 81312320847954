import { Record } from 'immutable';

import { sortBy, findIndex, filter } from 'underscore';

import {
    SORT_PATIENTS_BY_ASC_DATE,
    SORT_PATIENTS_BY_DESC_DATE,
    SORT_PATIENTS_BY_ASC_NUMERIC,
    SORT_PATIENTS_BY_DESC_NUMERIC,
} from '../utils/helpers';

const {
    GET_PATIENTS_REQUEST,
    GET_PATIENTS_SUCCESS,
    GET_PATIENTS_FAILURE,

    SORT_PATIENTS_REQUEST,

    FILTER_PATIENTS_REQUEST,

    RESET_PATIENT_PASSWORD_REQUEST,
    RESET_PATIENT_PASSWORD_SUCCESS,
    RESET_PATIENT_PASSWORD_FAILURE,

    SET_SINGLE_PATIENT_REQUEST,

    UPDATE_PATIENT_REQUEST_REQUEST,
} = require('./patientActions').constants;

const InitialState = Record({
    isFetching: false,
    error: null,
    allPatients: [],
    displayPatients: [],
    singlePatient: {},
    temporaryPassword: '',
});

const initialState = new InitialState();

export default function consultationReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case GET_PATIENTS_REQUEST:
    case RESET_PATIENT_PASSWORD_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SET_SINGLE_PATIENT_REQUEST: {
        const { allPatients } = state;
        let { singlePatient } = state;

        const patientIndex = findIndex(allPatients, (val) => val.id === payload);
        if (patientIndex !== -1) {
            singlePatient = allPatients[patientIndex];
        }

        return state.set('singlePatient', singlePatient);
    }

    case UPDATE_PATIENT_REQUEST_REQUEST: {
        const { allPatients } = state;
        let { singlePatient } = state;

        if (payload?.id) {
            const patientIndex = findIndex(allPatients, (val) => val.id === payload?.id);

            allPatients[patientIndex] = payload;
            singlePatient = payload;
        }

        return state.set('singlePatient', singlePatient).set('allPatients', allPatients);
    }

    case GET_PATIENTS_SUCCESS: {
        return state.set('isFetching', false)
            .set('allPatients', payload.data)
            .set('displayPatients', payload.data);
    }

    case GET_PATIENTS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case SORT_PATIENTS_REQUEST: {
        const { allPatients } = state;

        let displayPatients = allPatients;
        if (payload !== '') {
            if (payload === SORT_PATIENTS_BY_ASC_DATE) {
                displayPatients = sortBy(allPatients, 'created_at');
            }

            if (payload === SORT_PATIENTS_BY_DESC_DATE) {
                displayPatients = sortBy(allPatients, 'created_at').reverse();
            }

            if (payload === SORT_PATIENTS_BY_ASC_NUMERIC) {
                displayPatients = sortBy(allPatients, 'patient_id');
            }

            if (payload === SORT_PATIENTS_BY_DESC_NUMERIC) {
                displayPatients = sortBy(allPatients, 'patient_id').reverse();
            }
        }

        return state.set('displayPatients', displayPatients);
    }

    case FILTER_PATIENTS_REQUEST: {
        const { allPatients } = state;
        let displayPatients = allPatients;
        const { searchTerm, id } = payload;

        if (id) {
            const patient = allPatients.find((val) => val?.id === id);
            return state.set('displayPatients', [patient]);
        }

        if (searchTerm) {
            displayPatients = filter(allPatients, (str) => { if (str.patient_id.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) return str; return ''; });
        } else {
            displayPatients = allPatients;
        }

        return state.set('displayPatients', displayPatients);
    }

    case RESET_PATIENT_PASSWORD_SUCCESS:
        return state.set('isFetching', false).set('temporaryPassword', payload.data);

    case RESET_PATIENT_PASSWORD_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}
