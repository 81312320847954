/* eslint-disable */
import React from 'react';

export const TickCircleIcon = ({ h, w, f, s, style }) => (
    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="35" cy="35.5" r="35" fill="white" />
        <circle cx="35" cy="35.5" r="35" fill="white" />
        <path d="M39.1875 30.6583L33.1754 36.67L30.4261 33.9209C29.4119 32.9067 27.7672 32.9066 26.7528 33.9209C25.7385 34.9353 25.7385 36.5798 26.7528 37.594L31.3386 42.1796C32.3528 43.194 33.9977 43.194 35.0119 42.1796L42.8606 34.3312C43.8749 33.3168 43.8749 31.6723 42.8606 30.6581C41.8464 29.6439 40.2017 29.6439 39.1875 30.6583Z" fill={f || '#228855'} />
        <path d="M35 15.501C23.9719 15.501 15 24.4726 15 35.5001C15 46.5276 23.9719 55.4992 35 55.4992C46.0281 55.4992 55 46.5276 55 35.5001C55 24.4726 46.0281 15.501 35 15.501ZM35 50.3046C26.8364 50.3046 20.1948 43.6634 20.1948 35.5001C20.1948 27.3368 26.8364 20.6956 35 20.6956C43.1636 20.6956 49.8052 27.3368 49.8052 35.5001C49.8052 43.6634 43.1636 50.3046 35 50.3046Z" fill={f || '#228855'} />
    </svg>
);

export const ClockCircleIcon = ({ h, w, f, s, style }) => (
    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="35" cy="35.5" r="35" fill="white" />
        <path d="M35 15.501C23.9719 15.501 15 24.4726 15 35.5001C15 46.5276 23.9719 55.4992 35 55.4992C46.0281 55.4992 55 46.5276 55 35.5001C55 24.4726 46.0281 15.501 35 15.501ZM35 50.3046C26.8364 50.3046 20.1948 43.6634 20.1948 35.5001C20.1948 27.3368 26.8364 20.6956 35 20.6956C43.1636 20.6956 49.8052 27.3368 49.8052 35.5001C49.8052 43.6634 43.1636 50.3046 35 50.3046Z" fill={f || '#FF7F11'} />
        <path d="M40.027 44.7284C40.3023 45.0036 40.664 45.1415 41.0242 45.1415C41.3842 45.1415 41.7461 45.0038 42.0214 44.7284C42.5722 44.1776 42.5722 43.2847 42.0214 42.7339L35.1218 35.8343V27.2775C35.1218 26.4986 34.4903 25.8672 33.7114 25.8672C32.9326 25.8672 32.3011 26.4986 32.3011 27.2775V36.4184C32.3011 36.7924 32.4497 37.1511 32.7143 37.4156C32.7143 37.4157 32.7143 37.4157 32.7143 37.4157L40.027 44.7284Z" fill={f || '#FF7F11'} stroke={f || '#FF7F11'} />
    </svg>
);

export const DangerCircleIcon = ({ h, w, f, s, style }) => (
    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="35" cy="35.5" r="35" fill="white" />
        <path d="M41.3131 21.016C39.9836 18.7542 37.6237 17.4038 35.0001 17.4038C32.3765 17.4038 30.0166 18.754 28.687 21.016L16.0222 42.5618C14.6746 44.8544 14.6585 47.6011 15.9793 49.9093C17.2998 52.2175 19.676 53.5955 22.3353 53.5955H47.6647C50.324 53.5955 52.7001 52.2175 54.0207 49.9093C55.3415 47.6011 55.3254 44.8544 53.9778 42.5618L41.3131 21.016ZM50.1782 47.7107C49.656 48.6235 48.7164 49.1684 47.6647 49.1684H22.3353C21.2837 49.1684 20.344 48.6234 19.8218 47.7107C19.2995 46.798 19.3059 45.7117 19.8387 44.8051L32.5035 23.2593C33.0371 22.3516 33.947 21.8308 34.9999 21.8308C36.0528 21.8308 36.9629 22.3514 37.4963 23.2593L50.1611 44.8051C50.6943 45.7117 50.7006 46.7978 50.1782 47.7107Z" fill={f || '#DD1C14'} />
        <path d="M35.0002 29.0864C33.7778 29.0864 32.7867 30.0775 32.7867 31.2999V35.5564C32.7867 36.7788 33.7778 37.7699 35.0002 37.7699C36.2227 37.7699 37.2138 36.7788 37.2138 35.5564V31.2999C37.2138 30.0775 36.2227 29.0864 35.0002 29.0864Z" fill={f || '#DD1C14'} />
        <path d="M35.0002 40.3823C33.8423 40.3823 32.7331 41.4004 32.7867 42.5958C32.8404 43.7951 33.7593 44.8093 35.0002 44.8093C36.158 44.8093 37.2672 43.7913 37.2137 42.5958C37.16 41.3966 36.2411 40.3823 35.0002 40.3823Z" fill={f || '#DD1C14'} />
    </svg>
);

export const NoteIcon = ({ h, w, f, s, style }) => (
    <svg width="16" height="16" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4205 2.8298H6.23359V2.35043C6.23359 1.3368 5.4091 0.512305 4.39547 0.512305C3.38184 0.512305 2.55734 1.3368 2.55734 2.35043V2.8298H0.919219C0.544021 2.8298 0.239844 3.13398 0.239844 3.50918V19.8089C0.239844 20.1841 0.544021 20.4883 0.919219 20.4883H13.4205C13.7957 20.4883 14.0998 20.1841 14.0998 19.8089V3.50918C14.0999 3.13398 13.7957 2.8298 13.4205 2.8298ZM3.91609 2.35043C3.91609 2.08619 4.13123 1.87105 4.39547 1.87105C4.65971 1.87105 4.87484 2.08619 4.87484 2.35043V2.8298H3.91609V2.35043ZM12.7411 19.1295H1.59859V4.18855H4.87484V5.80296C4.87484 6.0672 4.65971 6.28234 4.39547 6.28234C4.13123 6.28234 3.91609 6.0672 3.91609 5.80296V5.44043C3.91609 5.06523 3.61192 4.76105 3.23672 4.76105C2.86152 4.76105 2.55734 5.06523 2.55734 5.44043V5.80296C2.55734 6.81659 3.38184 7.64108 4.39547 7.64108C5.4091 7.64108 6.23359 6.81659 6.23359 5.80296V4.18855H12.7411L12.7411 19.1295Z" fill="white" stroke="white" stroke-width="0.2" />
        <path d="M10.4773 10.167H3.86209C3.54212 10.167 3.28271 10.4264 3.28271 10.7464C3.28271 11.0663 3.54212 11.3257 3.86209 11.3257H10.4773C10.7973 11.3257 11.0567 11.0663 11.0567 10.7464C11.0567 10.4264 10.7973 10.167 10.4773 10.167Z" fill="white" />
        <path d="M10.4773 10.167H3.86209C3.54212 10.167 3.28271 10.4264 3.28271 10.7464C3.28271 11.0663 3.54212 11.3257 3.86209 11.3257H10.4773C10.7973 11.3257 11.0567 11.0663 11.0567 10.7464C11.0567 10.4264 10.7973 10.167 10.4773 10.167Z" stroke="white" />
        <path d="M8.19754 7.54156H10.4775C10.7974 7.54156 11.0568 7.28216 11.0568 6.96219C11.0568 6.64222 10.7974 6.38281 10.4775 6.38281H8.19754C7.87757 6.38281 7.61816 6.64222 7.61816 6.96219C7.61816 7.28216 7.87757 7.54156 8.19754 7.54156Z" fill="white" />
        <path d="M8.19754 7.54156H10.4775C10.7974 7.54156 11.0568 7.28216 11.0568 6.96219C11.0568 6.64222 10.7974 6.38281 10.4775 6.38281H8.19754C7.87757 6.38281 7.61816 6.64222 7.61816 6.96219C7.61816 7.28216 7.87757 7.54156 8.19754 7.54156Z" stroke="white" />
        <path d="M10.4773 12.7295H3.86209C3.54212 12.7295 3.28271 12.9889 3.28271 13.3089C3.28271 13.6288 3.54212 13.8882 3.86209 13.8882H10.4773C10.7973 13.8882 11.0567 13.6288 11.0567 13.3089C11.0567 12.9889 10.7973 12.7295 10.4773 12.7295Z" fill="white" />
        <path d="M10.4773 12.7295H3.86209C3.54212 12.7295 3.28271 12.9889 3.28271 13.3089C3.28271 13.6288 3.54212 13.8882 3.86209 13.8882H10.4773C10.7973 13.8882 11.0567 13.6288 11.0567 13.3089C11.0567 12.9889 10.7973 12.7295 10.4773 12.7295Z" stroke="white" />
        <path d="M10.4773 15.292H3.86209C3.54212 15.292 3.28271 15.5514 3.28271 15.8714C3.28271 16.1913 3.54212 16.4507 3.86209 16.4507H10.4773C10.7973 16.4507 11.0567 16.1913 11.0567 15.8714C11.0567 15.5514 10.7973 15.292 10.4773 15.292Z" fill="white" />
        <path d="M10.4773 15.292H3.86209C3.54212 15.292 3.28271 15.5514 3.28271 15.8714C3.28271 16.1913 3.54212 16.4507 3.86209 16.4507H10.4773C10.7973 16.4507 11.0567 16.1913 11.0567 15.8714C11.0567 15.5514 10.7973 15.292 10.4773 15.292Z" stroke="white" />
    </svg>
);

export const ClockCircleIconGreen = ({ h, w, f, s, style }) => (
    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="35" cy="35.5" r="35" fill="white" />
        <path d="M35 15.501C23.9719 15.501 15 24.4726 15 35.5001C15 46.5276 23.9719 55.4992 35 55.4992C46.0281 55.4992 55 46.5276 55 35.5001C55 24.4726 46.0281 15.501 35 15.501ZM35 50.3046C26.8364 50.3046 20.1948 43.6634 20.1948 35.5001C20.1948 27.3368 26.8364 20.6956 35 20.6956C43.1636 20.6956 49.8052 27.3368 49.8052 35.5001C49.8052 43.6634 43.1636 50.3046 35 50.3046Z" fill="#228855" />
        <path d="M40.0271 44.7284C40.3024 45.0036 40.6642 45.1415 41.0243 45.1415C41.3843 45.1415 41.7462 45.0038 42.0215 44.7284C42.5723 44.1776 42.5723 43.2847 42.0215 42.7339L35.1219 35.8343V27.2775C35.1219 26.4986 34.4904 25.8672 33.7116 25.8672C32.9327 25.8672 32.3013 26.4986 32.3013 27.2775V36.4184C32.3013 36.7924 32.4498 37.1511 32.7144 37.4156C32.7144 37.4157 32.7144 37.4157 32.7144 37.4157L40.0271 44.7284Z" fill="#228855" stroke="#228855" />
    </svg>
);

export const WarningIconOrange = ({ h, w, f, s, style }) => (
    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="35" cy="35.5" r="35" fill="white" />
        <path d="M41.3131 21.0164C39.9836 18.7547 37.6237 17.4043 35.0001 17.4043C32.3765 17.4043 30.0166 18.7545 28.687 21.0164L16.0222 42.5623C14.6746 44.8549 14.6585 47.6015 15.9793 49.9098C17.2998 52.218 19.676 53.596 22.3353 53.596H47.6647C50.324 53.596 52.7001 52.218 54.0207 49.9098C55.3415 47.6015 55.3254 44.8549 53.9778 42.5623L41.3131 21.0164ZM50.1782 47.7112C49.656 48.624 48.7164 49.1689 47.6647 49.1689H22.3353C21.2837 49.1689 20.344 48.6239 19.8218 47.7112C19.2995 46.7985 19.3059 45.7122 19.8387 44.8056L32.5035 23.2598C33.0371 22.3521 33.947 21.8313 34.9999 21.8313C36.0528 21.8313 36.9629 22.3519 37.4963 23.2598L50.1611 44.8056C50.6943 45.7122 50.7006 46.7983 50.1782 47.7112Z" fill="#FF7F11" />
        <path d="M35.0001 29.0869C33.7777 29.0869 32.7866 30.078 32.7866 31.3004V35.5568C32.7866 36.7793 33.7777 37.7703 35.0001 37.7703C36.2226 37.7703 37.2136 36.7793 37.2136 35.5568V31.3004C37.2136 30.078 36.2226 29.0869 35.0001 29.0869Z" fill="#FF7F11" />
        <path d="M35 40.3828C33.8422 40.3828 32.733 41.4009 32.7865 42.5963C32.8402 43.7956 33.7591 44.8098 35 44.8098C36.1579 44.8098 37.2671 43.7918 37.2135 42.5963C37.1598 41.397 36.2409 40.3828 35 40.3828Z" fill="#FF7F11" />
    </svg>
);

export const UrgentIconRed = ({ h, w, f, s, style }) => (
    <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="35" cy="35.5" r="35" fill="white" />
        <path d="M26.1905 29.1852L26.1905 29.1852L23.3037 46.5057C23.2475 46.8432 22.9555 47.0906 22.6133 47.0906H20.7656C20.0477 47.0906 19.4656 47.6727 19.4656 48.3906V51.1562C19.4656 51.5428 19.1522 51.8563 18.7656 51.8563H18.4219C17.7039 51.8563 17.1219 52.4383 17.1219 53.1562V53.5C17.1219 54.218 17.7039 54.8 18.4219 54.8H51.5781C52.2961 54.8 52.8781 54.218 52.8781 53.5V53.1562C52.8781 52.4383 52.2961 51.8563 51.5781 51.8563H51.2344C50.8478 51.8563 50.5344 51.5428 50.5344 51.1562V48.3906C50.5344 47.6727 49.9523 47.0906 49.2344 47.0906H47.3867C47.0445 47.0906 46.7525 46.8432 46.6963 46.5057L43.8095 29.1852C43.5016 27.3384 41.9181 25.9969 40.0458 25.9969H29.9542C28.0819 25.9969 26.4984 27.3384 26.1905 29.1852ZM29.0942 29.6692L29.0942 29.6691C29.1646 29.2464 29.5256 28.9406 29.9542 28.9406H40.0458C40.4743 28.9406 40.8353 29.2464 40.9058 29.6692L43.6735 46.2755C43.7446 46.7022 43.4156 47.0906 42.9831 47.0906H27.0169C26.5844 47.0906 26.2554 46.7022 26.3265 46.2755L29.0942 29.6692ZM22.4094 50.7344C22.4094 50.3478 22.7228 50.0344 23.1094 50.0344H46.8906C47.2772 50.0344 47.5906 50.3478 47.5906 50.7344V51.1562C47.5906 51.5428 47.2772 51.8563 46.8906 51.8563H23.1094C22.7228 51.8563 22.4094 51.5428 22.4094 51.1562V50.7344Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" stroke-linejoin="round" />
        <path d="M35 32.5086C34.1871 32.5086 33.5281 33.1676 33.5281 33.9805V37.1367C33.5281 37.9496 34.1871 38.6086 35 38.6086C35.8129 38.6086 36.4719 37.9496 36.4719 37.1367V33.9805C36.4719 33.1676 35.8129 32.5086 35 32.5086Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" />
        <path d="M35 40.5398C34.1871 40.5398 33.5281 41.1988 33.5281 42.0117C33.5281 42.8246 34.1871 43.4836 35 43.4836C35.8129 43.4836 36.4719 42.8246 36.4719 42.0117C36.4719 41.1988 35.8129 40.5398 35 40.5398Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" />
        <path d="M16.1719 33.0281C15.359 33.0281 14.7 33.6871 14.7 34.5C14.7 35.3129 15.359 35.9719 16.1719 35.9719H20.8594C21.6723 35.9719 22.3313 35.3129 22.3313 34.5C22.3313 33.6871 21.6723 33.0281 20.8594 33.0281H16.1719Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" />
        <path d="M49.1406 33.0281C48.3277 33.0281 47.6687 33.6871 47.6687 34.5C47.6687 35.3129 48.3277 35.9719 49.1406 35.9719H53.8281C54.641 35.9719 55.3 35.3129 55.3 34.5C55.3 33.6871 54.641 33.0281 53.8281 33.0281H49.1406Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" />
        <path d="M43.8986 23.5179C43.3239 24.0927 43.3239 25.0245 43.8986 25.5992C44.4733 26.174 45.4052 26.174 45.9799 25.5992L49.294 22.2852C49.8687 21.7104 49.8687 20.7786 49.294 20.2039C48.7192 19.6291 47.7874 19.6292 47.2127 20.2039L43.8986 23.5179Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" />
        <path d="M35 14.2C34.1871 14.2 33.5281 14.859 33.5281 15.6719V20.3594C33.5281 21.1723 34.1871 21.8313 35 21.8313C35.8129 21.8313 36.4719 21.1723 36.4719 20.3594V15.6719C36.4719 14.859 35.8129 14.2 35 14.2Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" />
        <path d="M20.6994 20.2015C20.1247 20.7763 20.1247 21.7081 20.6994 22.2828L24.0135 25.5969C24.5882 26.1716 25.52 26.1716 26.0947 25.5969C26.6695 25.0222 26.6695 24.0903 26.0947 23.5156L22.7807 20.2015C22.206 19.6268 21.2741 19.6268 20.6994 20.2015Z" fill="#DD1C14" stroke="#DD1C14" stroke-width="0.6" />
    </svg>
);

export const ArrowLeftIcon = ({ h, w, f, s, style }) => (
    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4678 0.849378C11.2992 0.680428 11.0989 0.54639 10.8785 0.454935C10.658 0.363481 10.4216 0.316406 10.183 0.316406C9.94427 0.316406 9.70793 0.363481 9.48746 0.454935C9.26699 0.54639 9.06672 0.680428 8.89812 0.849378L0.532971 9.21452C0.364022 9.38313 0.229983 9.5834 0.138529 9.80387C0.0470748 10.0243 0 10.2607 0 10.4994C0 10.7381 0.0470748 10.9744 0.138529 11.1949C0.229983 11.4153 0.364022 11.6156 0.532971 11.7842L8.89812 20.1493C9.06684 20.3181 9.26715 20.4519 9.48761 20.5432C9.70806 20.6346 9.94434 20.6815 10.183 20.6815C10.4216 20.6815 10.6579 20.6346 10.8783 20.5432C11.0988 20.4519 11.2991 20.3181 11.4678 20.1493C11.6365 19.9806 11.7704 19.7803 11.8617 19.5599C11.953 19.3394 12 19.1031 12 18.8645C12 18.6259 11.953 18.3896 11.8617 18.1692C11.7704 17.9487 11.6365 17.7484 11.4678 17.5797L4.39661 10.4903L11.4678 3.41906C12.1786 2.7083 12.1603 1.54192 11.4678 0.849378Z" fill="#3F8CC8"/>
    </svg>
);

export const CameraIcon = ({ h, w, f, s, style }) => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.33059H14.5185C15.337 2.33059 16 2.99376 16 3.81253V12.2596C16 13.0784 15.337 13.7416 14.5185 13.7416H1.48148C0.662963 13.7416 0 13.0784 0 12.2596V3.81253C0 2.99376 0.662963 2.33059 1.48148 2.33059H1.74083C1.74076 2.32613 1.74072 2.32166 1.74072 2.31719V1.91719C1.74072 1.47536 2.0989 1.11719 2.54072 1.11719C2.98255 1.11719 3.34072 1.47536 3.34072 1.91719V2.31719C3.34072 2.32166 3.34069 2.32613 3.34061 2.33059H4L4.60185 0.648576C4.68519 0.413317 4.90926 0.25586 5.15926 0.25586H10.8407C10.963 0.255752 11.0824 0.293415 11.1825 0.363704C11.2826 0.433993 11.3585 0.533481 11.4 0.648576L12 2.33059ZM14.5185 12.4078C14.6 12.4078 14.6667 12.3411 14.6667 12.2596V3.81253C14.6667 3.73103 14.6 3.66434 14.5185 3.66434H11.0593L10.7426 2.77887L10.3185 1.58961H5.67963L5.25556 2.77887L4.93889 3.66434H1.48148C1.4 3.66434 1.33333 3.73103 1.33333 3.81253V12.2596C1.33333 12.3411 1.4 12.4078 1.48148 12.4078H14.5185ZM5.03704 7.81379C5.03704 6.17624 6.36296 4.8499 8 4.8499C9.63704 4.8499 10.963 6.17624 10.963 7.81379C10.963 9.45134 9.63704 10.7777 8 10.7777C6.36296 10.7777 5.03704 9.45134 5.03704 7.81379ZM6.22222 7.81379C6.22222 8.79558 7.01852 9.59212 8 9.59212C8.98148 9.59212 9.77778 8.79558 9.77778 7.81379C9.77778 6.832 8.98148 6.03545 8 6.03545C7.01852 6.03545 6.22222 6.832 6.22222 7.81379Z" fill="#1C1D21"/>
    </svg>
);

export default TickCircleIcon;
