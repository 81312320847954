import React, { useEffect, useState } from 'react';

// import { Link } from 'react-router-dom';
// import { InfoOutlined } from '@ant-design/icons';
import { Button, Row, Col, Modal } from 'antd';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';

import { FiRefreshCw as RefreshIcon } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import {
    ArrowLeftIcon,
    CameraIcon,
} from '../../assets/icons';
import history from '../../core/utils/history';
// import FoxIcon from '../../assets/images/icons/fox-icon.png';
import iconInventory from '../../assets/images/profileIcons/iconInventory';

import Standard from '../../components/layouts/Standard';
import ArrowStatusBlock from '../../components/ArrowStatusBlock';
import DailyProgressBlock from '../../components/DailyProgressBlock';
import AlertsActionsBlock from '../../components/AlertsActionsBlock';
import ScoresBlock from '../../components/ScoresBlock';
import ChartBlock from '../../components/ChartBlock';
import ProfileIconModalForm from '../../components/ProfileIconModalForm';

import './singlePatient.scss';

import { getAlertsRequest } from '../../core/alert/alertActions';
import { getPatientsRequest, setSinglePatientRequest } from '../../core/patient/patientActions';

const SinglePatient = () => {
    const dispatch = useDispatch();

    const { singlePatient } = useSelector((state) => state.patient);
    const { singleClinic } = useSelector((state) => state.clinic);
    const { allPatients } = useSelector((state) => state.patient);
    const [isIconModalOpen, setIsIconModalOpen] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const image = iconInventory.find((val) => val?.id === singlePatient?.profile_icon_id);

    useEffect(() => {
        dispatch(getPatientsRequest({ clinic_id: singleClinic?.id }));
    }, []);

    useEffect(() => {
        dispatch(setSinglePatientRequest(singlePatient?.id));
    }, [allPatients]);

    useEffect(() => {
        setIsRefreshing(false);
    }, [singlePatient]);

    useEffect(() => {
        dispatch(getAlertsRequest({ user_id: singlePatient?.id }));
    }, []);

    useEffect(() => {
        setIsIconModalOpen(false);
    }, [image]);

    function getRange(startDate, endDate, type) {
        const fromDate = moment(startDate);
        const toDate = moment(endDate);
        const diff = toDate.diff(fromDate, type);
        const range = [];
        for (let i = 0; i < diff; i++) {
            range.push(moment(startDate).add(i, type).format('YYYY-M-D'));
        }
        return range;
    }

    function generateArrowStatus(arrow) {
        const journeyStage = singlePatient?.journey_stage;

        switch (arrow) {
        case 1:
            if (journeyStage === 1) { // JOURNEY_STAGE_EVALUATION
                return 2;
            }
            if (journeyStage < 2) {
                return 1;
            }
            break;
        case 2:
            if (journeyStage === 2) { // JOURNEY_STAGE_SET_UP
                return 2;
            }
            if (journeyStage < 3) {
                return 1;
            }
            break;
        case 3:
            if (journeyStage === 3) { // JOURNEY_STAGE_TELEHEALTH_VISIT
                return 2;
            }
            if (journeyStage < 4) {
                return 1;
            }
            break;
        case 4:
            if (journeyStage === 4) { // JOURNEY_STAGE_RE_EVALUAION
                return 2;
            }
            if (journeyStage < 5) {
                return 1;
            }
            break;
        default:
            return 0;
        }
        return 0;
    }

    const range = getRange(moment(singlePatient?.therapy_start_date), moment(singlePatient?.therapy_end_date), 'days');
    const assessmentMeta = singlePatient?.assessmentMeta;

    const getStatusText = (patient) => {
        if (patient?.urgentActionRequired) {
            return 'Urgent Action Required';
        } if (patient?.actionRequired) {
            return 'Customer Journey Action Required';
        }
        return 'No Action Required';
    };

    const getStatusColor = (patient) => {
        if (patient?.urgentActionRequired) {
            return 'danger';
        } if (patient?.actionRequired) {
            return 'warning';
        }
        return 'success';
    };

    const handleRefreshClick = () => {
        setIsRefreshing(true);
        dispatch(getPatientsRequest({ clinic_id: singleClinic?.id }));
    };

    return (
        <Standard title={`Patient - ${singlePatient?.id}`}>
            <div>
                <Button
                    onClick={() => history.goBack()}
                    icon={<ArrowLeftIcon />}
                    className="back-button"
                    shape="circle" />
                <div className="title-container">
                    <h1 className="page-title">Patient Record</h1>
                    <Button type="button" onClick={handleRefreshClick}>{isRefreshing ? 'Loading...' : (
                        <IconContext.Provider value={{ size: 24 }}>
                            <RefreshIcon />
                        </IconContext.Provider>
                    )}
                    </Button>
                </div>

                <div className="horizontal-line" />
                <ArrowStatusBlock
                    firstArrowStatus={generateArrowStatus(1)}
                    secondArrowStatus={generateArrowStatus(2)}
                    thirdArrowStatus={generateArrowStatus(3)}
                    fourthArrowStatus={generateArrowStatus(4)} />

                <Row className="patient-summary-block">
                    <Col xs={24} sm={24} md={24} lg={15} xl={15} className="left-col">
                        <div className="table-summary-block">
                            <Row>
                                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                    <div className="avatar-block" onClick={() => setIsIconModalOpen(true)}>
                                        {image?.image && <img src={image?.image} alt="Profile Icon" />}
                                        <div className="camera-icon">
                                            <CameraIcon />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Patient ID:</td>
                                                <td>{singlePatient?.patient_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Device ID:</td>
                                                <td>{singlePatient?.device_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Level:</td>
                                                <td>{singlePatient?.level ? singlePatient.level : 'N/a'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Therapy Start Date:</td>
                                                <td>{moment(singlePatient?.therapy_start_date).format('D/M/YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td>Therapy End Date:</td>
                                                <td>{moment(singlePatient?.therapy_end_date).format('D/M/YYYY')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={9} xl={9} className="right-col">
                        <div className="table-summary-block short-block">
                            <h4>Patient Status:</h4>
                            <div className="left-side">
                                <div className={`circle-icon ${getStatusColor(singlePatient)}`} /> {getStatusText(singlePatient)}
                            </div>
                        </div>
                    </Col>
                </Row>
                <h2 className="section-title">Daily Progress</h2>
                <DailyProgressBlock
                    days={range.map((val, ind) => {
                        let singleMeta = {};
                        if (assessmentMeta) {
                            singleMeta = assessmentMeta[moment(val, 'YYYY-M-D').format('YYYY-MM-DD')] || {};
                        }
                        const combined = {
                            maximumStimulationStrength: 0,
                            timeSpentAssessment: 0,
                            timeSpentTraining: 0,
                            maximumPulseWidth: 0,
                        };
                        if (singleMeta) {
                            Object.values(singleMeta).forEach((item) => {
                                combined.timeSpentAssessment += item.timeSpentAssessment;
                                combined.timeSpentTraining += item.timeSpentTraining;
                                if (combined.maximumStimulationStrength < item.maximumStimulationStrength) {
                                    combined.maximumStimulationStrength = item.maximumStimulationStrength;
                                }
                                if (combined.maximumPulseWidth < item.maximumPulseWidth) {
                                    combined.maximumPulseWidth = item.maximumPulseWidth;
                                }
                            });
                        }
                        return { ...combined, day: ind + 1, time: (combined.timeSpentTraining + combined.timeSpentAssessment), date: val };
                    })} />
            </div>
            <h2 className="section-title">Alerts & Actions</h2>
            <AlertsActionsBlock userId={singlePatient?.id} />
            <h2 className="section-title">Scores</h2>
            <ScoresBlock />
            <h2 className="section-title">Daily Time</h2>
            <ChartBlock />
            <br />
            <br />
            <br />
            {(isIconModalOpen) && (
                <Modal
                    footer={null}
                    onCancel={() => setIsIconModalOpen(false)}
                    width={1000}
                    open={isIconModalOpen}>
                    <ProfileIconModalForm patientId={singlePatient?.id} />
                </Modal>
            )}

        </Standard>
    );
};

export default SinglePatient;

SinglePatient.propTypes = {};
